import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import PieChartComp from '../common/charts/piChart-builder';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
import { Loader } from '../../Common/Loader';
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?:number;
  projectId:string;
}
export const DbNoiseLog:React.FC<LogProps> = ({logTitle, accessToken, clientAPIURL, filterVal, projectId }) => {
  const [noiseLogData, setNoiseLogData] = useState<null | any>(null);
  const [noiseLogColumns, setNoiseLogColumns] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken, projectId);
        const finalData = constructData(jResponse);
        setNoiseLogData(finalData.items);
        setNoiseLogColumns(finalData.columns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ✅ Always update loading state
      }
    }
    fetchData();
  }, []);
  if (isLoading) {
    return <Loader />; // ✅ Show a loading indicator while fetching
  }

  return noiseLogData && noiseLogColumns?.length > 0 ? (
  <CreateNoiseLogCharts filterVal={filterVal} setNoiseLogData={setNoiseLogData} noiseLogData={noiseLogData} noiseLogColumns={noiseLogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} />
  
  ):(
      <View>
        <PieChartComp 
          data={[]} 
          colorPallate={interpolateBlues} 
          subTitle="(Cumulative)" 
          ChartTitle="Noise" 
        />
      </View>
    )
}
export default DbNoiseLog;
interface CreateNoiseLogChartsProps {
  noiseLogData: any;
  noiseLogColumns: any;
  logTitle: string;
  clientAPIURL: string;
  setNoiseLogData: any;
  filterVal?:number;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({filterVal, setNoiseLogData, noiseLogData, noiseLogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);

  const [formMode, setformMode] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  //setformMode={} setSelectedRows={} setShowPanel={}
  const ColumnsWithIDColumn = noiseLogColumns && [
    ...noiseLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  //const sortedData = noiseLogData//[...noiseLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const currentDate = new Date();  // Get the current date
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

    const dbFilteredData = filterVal===0? noiseLogData : noiseLogData.filter((item:any) => {
      // Assuming item.Date is a JavaScript Date object
      const itemDate = item.Date && new Date(item.Date);
      return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
    });
  const updatedData = dbFilteredData.map((item: any) => {
    const maxLimit: number = Math.max(
      parseFloat(item.MonitoringResults1),
      parseFloat(item.MonitoringResults2),
      parseFloat(item.MonitoringResults3)
    );

    const NoiseLevel: number =
      item.Hour24H === ''
        ? null
        : item.TimePeriod === 'Day' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
          ? 75
          : item.TimePeriod === 'Evening' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
            ? 65
            : item.TimePeriod === 'Night' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
              ? 45
              : item.TimePeriod === 'Day' && item.AreaClassification === 'Category A'
                ? 50
                : item.TimePeriod === 'Day' && item.AreaClassification === 'Category B'
                  ? 55
                  : item.TimePeriod === 'Day' && item.AreaClassification === 'Category C'
                    ? 60
                    : item.TimePeriod === 'Day' && item.AreaClassification === 'Category D'
                      ? 65
                      : item.TimePeriod === 'Day' && item.AreaClassification === 'Roadsides'
                        ? 70
                        : item.TimePeriod === 'Night' && item.AreaClassification === 'Category A'
                          ? 40
                          : item.TimePeriod === 'Night' && item.AreaClassification === 'Category B'
                            ? 45
                            : item.TimePeriod === 'Night' && item.AreaClassification === 'Category C'
                              ? 50
                              : item.TimePeriod === 'Night' && item.AreaClassification === 'Category D'
                                ? 55
                                : item.TimePeriod === 'Night' && item.AreaClassification === 'Roadsides'
                                  ? 65
                                  : 80;

    const Conformity = maxLimit >= NoiseLevel ? 'Conformity' : 'Non Conformity';
    const pieKey = Conformity;
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Conformity, Period, maxLimit, key, Date, pieKey };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const datasets = Object.values(lineChartData).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets
  };
  const conformityTypes = new Set();
  conformityTypes.add('Conformity');
  conformityTypes.add('Non Conformity');
  const legendData1 = [...conformityTypes].map((type: any, index: any) => ({
    name: type,
  }));

  const dayNoise = newData.filter((a) => a.TimePeriod == 'Day');
  const eveNoise = newData.filter((a) => a.TimePeriod == 'Evening');
  const nightNoise = newData.filter((a) => a.TimePeriod == 'Night');


  const pieDataTotal: any = {};
  updatedData.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataTotal[Key]) {
      pieDataTotal[Key] += 1;
    } else {
      pieDataTotal[Key] = 1;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byConformityPieData = Object.keys(pieDataTotal).map((key, index) => ({
    name: key,
    value: pieDataTotal[key],
    color: colorType5(index.toString()),//colorScale2(pieDataTotal[key] / Math.max(...Object.values(pieDataTotal) as number[])),
  }));



  /**======================================================= */
  const pieDataDay: any = {};
  dayNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataDay[Key]) {
      pieDataDay[Key] += 1;
    } else {
      pieDataDay[Key] = 1;
    }
  });
  const colorScale3 = scaleSequential().domain([0, 1]).interpolator(interpolateBuGn);
  const byConformityPieDataDay = Object.keys(pieDataDay).map((key, index) => ({
    name: key,
    value: pieDataDay[key],
    color: colorType5(index.toString()),//colorScale3(pieDataDay[key] / Math.max(...Object.values(pieDataDay) as number[])),
  }));



  /**======================================================= */
  /**======================================================= */

  const pieDataEve: any = {};
  eveNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0

    if (pieDataEve[Key]) {
      pieDataEve[Key] += 1;
    } else {
      pieDataEve[Key] = 1;
    }
  });
  const colorScale4 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byConformityPieDataEve = Object.keys(pieDataEve).map((key, index) => ({
    name: key,
    value: pieDataEve[key],
    color: colorType3(index.toString()),//colorScale4(pieDataEve[key] / Math.max(...Object.values(pieDataEve) as number[])),
  }));



  /**======================================================= */
  /**======================================================= */

  const pieDataNight: any = {};
  nightNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0

    if (pieDataNight[Key]) {
      pieDataNight[Key] += 1;
    } else {
      pieDataNight[Key] = 1;
    }
  });
  const colorScale5 = scaleSequential().domain([0, 1]).interpolator(interpolateBuPu);
  const byConformityPieDataNight = Object.keys(pieDataNight).map((key, index) => ({
    name: key,
    value: pieDataNight[key],
    color: colorType5(index.toString()),//colorScale5(pieDataNight[key] / Math.max(...Object.values(pieDataNight) as number[])),
  }));



  /**======================================================= */


  const lineChartConformityDataDay: number[] = [];
  const lineChartNonConformityDataDay: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = dayNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = dayNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataDay.push(filteredDataConf);
    lineChartNonConformityDataDay.push(filteredDataNonConf);
  });

  const lineChartDataDay = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);
  const datasets2 = Object.values(lineChartDataDay).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataDayChart = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: datasets2
  };

  const lineChartConformityDataEve: number[] = [];
  const lineChartNonConformityDataEve: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = eveNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = eveNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataEve.push(filteredDataConf);
    lineChartNonConformityDataEve.push(filteredDataNonConf);
  });

  const lineChartDataEve = {
    Line1: lineChartConformityDataEve,
    Line2: lineChartNonConformityDataEve
  };

  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const datasets3 = Object.values(lineChartDataEve).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataEveChart = {
    labels: uniquePeriod,
    datasets: datasets3
  };


  const lineChartConformityDataNight: number[] = [];
  const lineChartNonConformityDataNight: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = nightNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = nightNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataNight.push(filteredDataConf);
    lineChartNonConformityDataNight.push(filteredDataNonConf);
  });

  const lineChartDataNight = {
    Line1: lineChartConformityDataNight,
    Line2: lineChartNonConformityDataNight
  };

  const colorScaleLine4 = scaleOrdinal(schemeCategory10);
  const datasets4 = Object.values(lineChartDataNight).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine4(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataNightChart = {
    labels: uniquePeriod,
    datasets: datasets4
  };


  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = updatedData.length;
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Noise Monitoring'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View style={CommonStyles.container}>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieData} colorPallate={interpolateBlues} subTitle={'(Day/Eve/Night)'} ChartTitle={"Total"} /></View>
        {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Day/Eve/Night)'} ChartTitle={"Total"} /></View>

        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataDay} colorPallate={interpolateBlues} subTitle={'(Day)'} ChartTitle={"Total"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataEve} colorPallate={interpolateBlues} subTitle={'(Evening)'} ChartTitle={"Total"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataNight} colorPallate={interpolateBlues} subTitle={'(Night)'} ChartTitle={"Total"} /></View>
      </View>

      <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataDayChart} label={uniquePeriod} subTitle={'(Day)'} ChartTitle={"Total"} /></View>
      <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataEveChart} label={uniquePeriod} subTitle={'(Evening)'} ChartTitle={"Total"} /></View>
      <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataNightChart} label={uniquePeriod} subTitle={'(Night)'} ChartTitle={"Total"} /></View> */}
    </View>
    </View >
  );
};