import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, ScrollView } from 'react-native';
import PaperTableDynamic from '../common/controls/TableProvider';
import { getAllItems, getItemById, triggerReportRequest } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { Loader } from '../../Common/Loader';
import { logStyles } from './LogStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs'; import ToastMessage from '../common/controls/Toast';
;
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ReportRequest(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setLogData(json.items);
        setLogColumns(json.columns);
        //setLogData(noiseData.items);
        //setLogColumns(noiseData.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return LogData && LogColumns ? <CreateReportRequest setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateReportRequestProps {
  LogData: any;
  LogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}

const CreateReportRequest: React.FC<CreateReportRequestProps> = ({ setLogData, LogData, LogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const updatedData = LogData.map((item: any) => {

    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    //const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, key, Date };
  });
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  async function handleDataUpdate(item: any) {
    if (!item.ID) return;

    try {
      const accessToken = await getAccessToken();
      const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID);
      
      // Update data
      const data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
      const newItem = { ...result.items[0] };
      setNewData([...data, newItem]);

      // Determine the template and period
      const template = result.items[0]?.Template;
      let period = null;
      //getItemById(logTitle, accessToken, clientAPIURL, siteId, result.items[0].ID)
      switch (template) {
        case "Monthly":
          period = `${result.items[0]?.Month} ${result.items[0]?.Year}`;
          break;
        case "Quarterly":
          period = `${result.items[0]?.Quarter} ${result.items[0]?.Year}`;
          break;
        case "Bi-Annual":
          period = `${result.items[0]?.HalfYear} ${result.items[0]?.Year}`;
          break;
        default:
          period = result.items[0]?.ReportingPeriod;
      }

      if (!period) {
        setToastText("Something went wrong. Invalid reporting period.");
        setToastVisibility(true);
        console.log("Invalid period:", period);
        return;
      }

      //result.items[0].Author
      const reportData = {
        template: template,
        period: period,
        logTitle: logTitle,
        AuthorId:result.items[0].Author
      };
      // Call API
      const response = await triggerReportRequest(logTitle, accessToken, clientAPIURL, reportData);

      if (response.ok) {
        setToastText("Report request placed. You will be notified by email once the report is ready.");
        setToastVisibility(true);
      } else {
        const errorMessage = await response.text();
        setToastText(`Something went wrong: ${errorMessage}`);
      }
    }
    catch (error) {
      console.error("An error occurred:", error);
      setToastText("An unexpected error occurred.");
      setToastVisibility(true);
    }
  }

  const handleToastDismiss = () => {
    setToastVisibility(false);
  };
  return (
    <View style={logStyles.logRoot}>
      <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
        <View>
          <View>
            <ToastMessage
              show={toastVisibility}
              actionLabel="Dismiss"
              toastMessage={toastText}
              duration={3000}
              onDismiss={handleToastDismiss}
            />
          </View>
          <View style={logStyles.container}>
            <View style={logStyles.cardTwo}>
              {
                newData &&
                ColumnsWithIDColumn && (
                  <PaperTableDynamic
                    data={newData}
                    columns={ColumnsWithIDColumn}
                    siteId={siteId}
                    logTitle={logTitle}
                    updateData={handleDataUpdate}
                    isConsolidatedTable={false}
                    funcSetShowFilter={setShowFilter}
                  />
                )
              }
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
