import React, { useEffect, useRef, useState } from "react";
import { Pressable, View, StyleSheet } from "react-native";
import { CommonStyles, EnableExport, FilterChoices, GetConsolidatedLog, constructData, filterData, handleExportClick, handleFilterReset } from "../ConsolidatedLogs/ConsolidatedController";
import { interpolateBlues, interpolateCool, schemeCategory10 } from "d3-scale-chromatic";
import { colorType3, colorType5, logStyles } from "../LogControllers/LogStyles";
import ConsolLogCountCard from "../ConsolidatedLogs/ConsolLogCountCard";
import { scaleOrdinal, scaleSequential } from "d3-scale";
import GeoMap from '../common/controls/GeoMap';
import { Loader } from "../../Common/Loader";
import PieChartComp from "../common/charts/piChart-builder";
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?: number;
  projectId:string;
}
export const DbObservation: React.FC<LogProps> = ({ logTitle, accessToken, clientAPIURL, filterVal, projectId }) => {
  const [observationLogData, setLogData] = useState<null | any>(null);
  const [observationLogColumns, setLogColumns] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken, projectId);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ✅ Always update loading state
      }
    }
    fetchData();
  }, [logTitle, clientAPIURL, accessToken]);

  if (isLoading) {
    return <Loader />; // ✅ Show a loading indicator while fetching
  }

  return (
    <View>
      {observationLogData && observationLogData.length && observationLogData.length > 0 && observationLogColumns ? (
        <CreateLogCharts
          setObservationLogData={setLogData}
          observationLogData={observationLogData}
          observationLogColumns={observationLogColumns}
          logTitle={logTitle}
          clientAPIURL={clientAPIURL}
          filterVal={filterVal}
        />
      )
        : (
          <View>
            <PieChartComp
              data={[]}
              colorPallate={interpolateBlues}
              subTitle="(Cumulative)"
              ChartTitle="Observations"
            />
          </View>
        )
      }
      {/* </ShimmerPlaceholder> */}
    </View>

  )
}
export default DbObservation;
interface CreateLogChartsProps {
  observationLogData: any;
  observationLogColumns: any;
  filterVal?: number;
  logTitle: string;
  clientAPIURL: string;
  setObservationLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ filterVal, setObservationLogData, observationLogData, observationLogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const ColumnsWithIDColumn = observationLogColumns && [
    ...observationLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];

  //const sortedData = observationLogData//[...observationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));


  const currentDate = new Date();  // Get the current date
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

  const dbFilteredData = filterVal === 0 ? observationLogData : observationLogData.filter((item: any) => {
    // Assuming item.Date is a JavaScript Date object
    const itemDate = item.Date && new Date(item.Date);
    return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
  });

  const updatedData = dbFilteredData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const DueDate = item.DueDate && item.DueDate != null ? returnDate(item.DueDate) : null;
    const DateClosedOut = item.DateClosedOut && item.DateClosedOut != null ? returnDate(item.DateClosedOut) : null;
    const Status = checkStatus(item.ClosedOut)
    const ReferenceNumber = item.ReferenceNumber === null ? item.ID : item.ReferenceNumber + "-" + item.ID;
    return { ...item, Period, key, Date, ReferenceNumber, DueDate, DateClosedOut, Status };
  });

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  const recordsWithCoordinates = updatedData && updatedData.filter((record: any) => {


    let loc = record.Location && record.Location;
    if (loc && loc.includes('coords')) {
      const location = JSON.parse(record.Location);
      return location && location.coords && location.coords.latitude && location.coords.longitude;
    }
  });
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkStatus(div: string) {
    switch (div) {
      case "Yes": return "Closed";
      case "No": return "Open";
      case "Escalated": return "Escalated";
      default: return null
    }
  }
  /**
   * Pie Chart by Status
   */
  const pieDataStatus: any = {};
  newData.forEach((item: any) => {
    const Key = item.Status;
    if (pieDataStatus[Key]) {
      pieDataStatus[Key] += 1;
    } else {
      pieDataStatus[Key] = 1;
    }
  });
  const StatusPieData = Object.keys(pieDataStatus).map((key, index) => ({
    name: key,
    value: pieDataStatus[key],
    color: colorType5(index.toString()),//colorScaleStatus(pieDataStatus[key] / Math.max(...Object.values(pieDataStatus) as number[])),
  }));

  const TotalCount = recordsWithCoordinates.length//newData.length;
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Observations'} number={Math.round(TotalCount)} />
        </View>
      </View>


      <View style={CommonStyles.container}>
        <View style={[CommonStyles.card, { height: 400 }]}>
          {
            recordsWithCoordinates && recordsWithCoordinates.length > 0 ?
              <GeoMap data={recordsWithCoordinates} />
              :
              <PieChartComp data={StatusPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Status"} />
          }
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});