import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, Dimensions, Modal, ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import PieChartComp from '../common/charts/piChart-builder';
import { scaleOrdinal, scaleSequential } from 'd3-scale';

import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
import { Loader } from '../../Common/Loader';
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?:number;
  projectId:string;
}
export const DbImportedSoilLog:React.FC<LogProps> = ({logTitle, accessToken, clientAPIURL, filterVal, projectId}) => {

  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken, projectId);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ✅ Always update loading state
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />; // ✅ Show a loading indicator while fetching
  }

  return LogData && LogColumns?.length > 0 ? (
  <CreateNoiseLogCharts filterVal={filterVal} setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} />
  ):(
      <View>
        <PieChartComp 
          data={[]} 
          colorPallate={interpolateBlues} 
          subTitle="(Cumulative)" 
          ChartTitle="Imported Soil" 
        />
      </View>
    )
}
export default DbImportedSoilLog;
interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
  filterVal?:number;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({filterVal, setLogData, LogData, LogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);;

  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  //const sortedData = LogData//[...LogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const currentDate = new Date();  // Get the current date
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

    const dbFilteredData = filterVal===0? LogData : LogData.filter((item:any) => {
      // Assuming item.Date is a JavaScript Date object
      const itemDate = item.Date && new Date(item.Date);
      return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
    });

  const updatedData = dbFilteredData.map((item: any) => {
    const maxLimit: number = Math.max(
      parseFloat(item.MonitoringResults1),
      parseFloat(item.MonitoringResults2),
      parseFloat(item.MonitoringResults3)
    );

    //const Conformity = maxLimit >= NoiseLevel ? 'Conformity' : 'Non Conformity';
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, maxLimit, key, Date };
  });
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }

  /**
   * Start Constructing data for Pie Chart by Soil Type
   */
  const pieData: any = {};
  updatedData.forEach((item: any) => {
    const soilType = item.SoilType;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData[soilType]) {
      pieData[soilType] += quantitym3;
    } else {
      pieData[soilType] = quantitym3;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const bysoilTypePieData = Object.keys(pieData).map((soilType, index) => ({
    name: soilType,
    value: pieData[soilType],
    color: colorType5(index.toString()),//colorScale(pieData[soilType] / Math.max(...Object.values(pieData) as number[])),
  }));



  /**
   * end Constructing data for Pie Chart by Soil Type
   * start Constructing data for Pie Chart by Use
   */
  const pieData2: any = {};
  updatedData.forEach((item: any) => {
    const Use = item.Use;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData2[Use]) {
      pieData2[Use] += quantitym3;
    } else {
      pieData2[Use] = quantitym3;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byUsePieData = Object.keys(pieData2).map((use, index) => ({
    name: use,
    value: pieData2[use],
    color: colorType3(index.toString()),//colorScale2(pieData2[use] / Math.max(...Object.values(pieData2) as number[])),
  }));


  /**
   * end Constructing data for Pie Chart by Soil Type
   * Start constructig data for lineChart 1
   */

  const soilTypes = new Set();

  // Group the data by period and Soil type
  const groupedData = updatedData.reduce((result: any, item: any) => {
    const period = item.Period;
    const soilType = item.SoilType;
    const quantity = item.Quantitym3 || 0;

    const key = period + '_' + soilType;
    if (result[key]) {
      result[key] += quantity;
    } else {
      result[key] = quantity;
    }

    soilTypes.add(soilType);

    return result;
  }, {});

  // Generate the line chart data for each Soil type

  const colorScale1 = scaleOrdinal(schemeCategory10);

  const lineData = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: [],
  };

  // Generate the line chart data for each Soil type
  soilTypes.forEach((soilType: any, index: any) => {
    const dataset = {
      data: [],
      color: (opacity = 1) => colorScale1(index) + Math.round(opacity * 255).toString(16),
      strokeWidth: 2,
    };

    Object.entries(groupedData).forEach(([key, value]) => {
      const [period, ft] = key.split('_');
      if (ft === soilType) {
        dataset.data.push(value);
      }
    });

    lineData.datasets.push(dataset);
  });
  const legendData1 = [...soilTypes].map((type: any, index: any) => ({
    name: type,
  }));

  const useTypes = new Set();

  // Group the data by period and Soil type
  const groupedData2 = updatedData.reduce((result: any, item: any) => {
    const period = item.Period;
    const use = item.Use;
    const quantity = item.Quantitym3 || 0;

    const key = period + '_' + use;
    if (result[key]) {
      result[key] += quantity;
    } else {
      result[key] = quantity;
    }

    useTypes.add(use);

    return result;
  }, {});

  // Generate the line chart data for each Soil type

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);

  const lineData2 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: [],
  };

  // Generate the line chart data for each Soil type
  useTypes.forEach((use: any, index: any) => {
    const dataset = {
      data: [],
      color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
      strokeWidth: 2,
    };

    Object.entries(groupedData2).forEach(([key, value]) => {
      const [period, ft] = key.split('_');
      if (ft === use) {
        dataset.data.push(value);
      }
    });

    lineData2.datasets.push(dataset);
  });

  const legendData2 = [...useTypes].map((type: any, index: any) => ({
    name: type,
  }));

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };
  const fuleTypeLabels = LogColumns.find((col: any) => col.columnName === 'SoilType')?.choiceChoices;
  const soilTypesTable = fuleTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });
  const useTypeLabels = LogColumns.find((col: any) => col.columnName === 'Use')?.choiceChoices;
  const useTypesTable = useTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });

  const groupedDataTable = newData.reduce((result, item) => {
    const { Period, SoilType, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      soilTypesTable.forEach((soilType: any) => {
        result[Period][soilType] = 0; // Initialize with 0 for each SoilType
      });
    }
    result[Period][SoilType] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each SoilType within each Period
  const transformedDataTable = Object.values(groupedDataTable).map((values: any) => {
    const sumBysoilType = soilTypesTable.reduce((sum: number, soilType: string) => sum + values[soilType], 0);
    return {
      ...values,
      "Total (Soil Type)": sumBysoilType
    };
  });

  const groupedUseDataTable = newData.reduce((result, item) => {
    const { Period, Use, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      useTypesTable.forEach((useType: any) => {
        result[Period][useType] = 0; // Initialize with 0 for each SoilType
      });
    }
    result[Period][Use] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each SoilType within each Period
  const transformedUseDataTable = Object.values(groupedUseDataTable).map((values: any) => {
    const sumByUseType = useTypesTable.reduce((sum: number, useType: string) => sum + values[useType], 0);
    return {
      ...values,
      "Total (Use Type)": sumByUseType
    };
  });


  // Create an object to store the combined data
  const combinedData: { [key: string]: { [key: string]: number } } = {};

  // Merge datasets based on Period
  transformedDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });

  transformedUseDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });

  // Convert the combinedData object back to an array
  const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
    Period,
    ...values
  }));


  const columnKeys = mergedData.length > 0 ? Object.keys(mergedData[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = updatedData.reduce((total:any, item:any) => {
    if (item.QuantityTonne) {
      return total + item.QuantityTonne;
    }
    return total;
  }, 0);
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Soil (Tonnes)'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View style={[CommonStyles.container, CommonStyles.divider]}>
        <View key={1} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={bysoilTypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Soil Qty(m3) / Type"} /></View>
        {/* <View key={2} style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Soil Qty(m3) / Type"} /></View>
        <View key={3} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byUsePieData} colorPallate={interpolateGnBu} subTitle={'(Cumulative)'} ChartTitle={"Soil Qty(m3) / Use"} /></View>
        <View key={4} style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData2} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Soil Qty(m3) / Type"} /></View> */}
      </View>
    </View>
  );
};