import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import { IconButton } from "react-native-paper";
import { useAuth } from "../../../providers/AuthProvider";
import DbObservation from "./Observation";
import DbEnergyLog from "./EnergyLog";
import { CommonStyles } from "./ConsolidatedController";
import DbWaterLog from "./WaterLog";
import DbWasteLog from "./WasteLog";
import DbWasteWaterLog from "./WasteWaterLog";
import DbSpoilLog from "./SpoilLog";
import DbHazardousMaterial from "./HazardousMaterial";
import DbImportedSoilLog from "./ImportedSoilLog";
import DbRecycledMaterial from "./RecycledMaterial";
import DbRegionalMaterial from "./RegionalMaterial";
import DbNoiseLog from "./NoiseLog";
import DbPM10 from "./PM10Monitoring";
import DbVibrationLog from "./VibrationMonitoring";
import DbTrainingLog from "./TrainingLog";
import ButtonControl from "../common/controls/Button";
import ControlStyles from "../common/controls/ControlStyles";
import { DbPM2P5 } from "./PM2P5Monitoring";
import { useNav } from "../../../providers/NavProvider";
interface LogProps {
  setModalVisible?: any;
  modalVisible?: boolean;
  clientAPIURL: string;
  Source?: string;
  ContinueToSite?: () => void;
  sitesResp: any;
  projectId:string;
}
export const HomeDashboard: React.FC<LogProps> = ({ clientAPIURL, setModalVisible, modalVisible, Source, ContinueToSite, sitesResp, projectId }) => {

  const { getAccessToken } = useAuth();
  const [accessToken, setAccessToken] = useState(null)
  const [filterVal, setFilterVal] = useState(180);
  useEffect(() => {
    async function fetchData() {
      setAccessToken(await getAccessToken());
    }
    fetchData();
  }, []);
  const filterData = (d: number) => {
    setFilterVal(d);
  }
  const continueToSite = () => {
    ContinueToSite();
  }
  return (
    <ScrollView>
      <View>
        <View style={{ flexDirection: 'row', gap: 10, alignItems: 'flex-end', justifyContent: 'flex-end', alignSelf: 'flex-end' }}>

          <ScrollView horizontal>
            <View><View style={ControlStyles.buttonContainer}><ButtonControl Title={'30 Days'} mode={'contained'} onPress={() => filterData(30)} disabled={false} style={filterVal === 30 ? styles.filterButtons : null} />
            </View></View>
            <View><View style={ControlStyles.buttonContainer}><ButtonControl Title={'90 Days'} mode={'contained'} onPress={() => filterData(90)} disabled={false} style={filterVal === 90 ? styles.filterButtons : null} />
            </View></View>
            <View><View style={ControlStyles.buttonContainer}><ButtonControl Title={'180 Days'} mode={'contained'} onPress={() => filterData(180)} disabled={false} style={filterVal === 180 ? styles.filterButtons : null} />
            </View></View>
            <View><View style={ControlStyles.buttonContainer}><ButtonControl Title={'ALL'} mode={'contained'} onPress={() => filterData(0)} disabled={false} style={filterVal === 0 ? styles.filterButtons : null} />
            </View></View>
          </ScrollView>

          {/* <IconButton icon="home-assistant" iconColor={"black"} size={50} onPress={() => { setModalVisible(!modalVisible) }} /> */}
          {sitesResp && <ButtonControl icon={'arrow-right-circle-outline'} Title={'Packages'} mode={'contained'} onPress={() => { Source === 'Client' ? continueToSite() : setModalVisible(!modalVisible) }} disabled={false} />}
        </View>
        {
          (accessToken) ?
            <View style={CommonStyles.container}>
              <View style={CommonStyles.card}>
                <DbObservation filterVal={filterVal} logTitle={"Environmental Issues Observations"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbEnergyLog filterVal={filterVal} logTitle={"Energy Log"} accessToken={accessToken} clientAPIURL={clientAPIURL} projectId={projectId} /></View>
              <View style={CommonStyles.card}>
                <DbWaterLog filterVal={filterVal} logTitle={"Water Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbWasteWaterLog filterVal={filterVal} logTitle={"Wastewater Tracking Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbWasteLog filterVal={filterVal} logTitle={"Waste Tracking Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbSpoilLog filterVal={filterVal} logTitle={"Spoil Tracking Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbHazardousMaterial filterVal={filterVal} logTitle={"Hazardous Material Storage Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbImportedSoilLog filterVal={filterVal} logTitle={"Imported Soil Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbRecycledMaterial logTitle={"Recycled Materials Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbRegionalMaterial logTitle={"Regional Materials Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbTrainingLog filterVal={filterVal} logTitle={"Training Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbNoiseLog filterVal={filterVal} logTitle={"Noise Monitoring Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbVibrationLog filterVal={filterVal} logTitle={"Vibration Monitoring Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbPM10 filterVal={filterVal} logTitle={"PM10 Monitoring Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>
              <View style={CommonStyles.card}>
                <DbPM2P5 filterVal={filterVal} logTitle={"PM2.5 Monitoring Log"} accessToken={accessToken} clientAPIURL={clientAPIURL}  projectId={projectId}/></View>

            </View>
            :
            <View><Text>Loading</Text></View>
        }
      </View>
    </ScrollView>
  )
}
export default HomeDashboard;
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  filterButtons: {
    backgroundColor: 'green'
  }
});