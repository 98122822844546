import React, { useEffect } from "react";
import { createContext, useContext } from "react";
import { ISitee } from "../components/ClientLanding";
import { useAuth } from "./AuthProvider";
import { useClient } from "./ClientProvider";


const NavContext = createContext({
    currentLeafObj: null as ISitee,
    sitesResp: null as ISitee,
    setSitesResp: (sitesResp: ISitee) => { },
    setCurrentLeafObj: (sitesResp: ISitee) => { },
    fetchNavForClient: (): Promise<ISitee> => { return Promise.resolve(null) }




});
export type Props = {
    children: React.ReactNode;
};

const NavProvider = (props: Props) => {
    const [currentLeafObj, setCurrentLeafObj] = React.useState<ISitee>(null);
    const [sitesResp, setSitesResp] = React.useState<ISitee>(null);
    const { getAccessToken } = useAuth();
    const { clientName, clientAPIURL } = useClient();

    useEffect(() => {
        if (clientName) {
            fetchNavForClient();
        }
    }, [clientName]);


    const fetchNavForClient = async (): Promise<ISitee> => {

        const accessToken = await getAccessToken();
        try {
            const response = await fetch(
                clientAPIURL + "api/sites",
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
            const jsonData: ISitee = await response.json();
            setSitesResp(jsonData);
            setCurrentLeafObj(jsonData);
            return jsonData;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        return {} as ISitee;
    };




    return (
        <NavContext.Provider
            value={{
                currentLeafObj,
                sitesResp,
                setSitesResp,
                setCurrentLeafObj,
                fetchNavForClient
            }}
        >
            {props.children}
        </NavContext.Provider>
    );
}
const useNav = () => useContext(NavContext);

export { NavProvider, useNav };