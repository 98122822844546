import { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, Text, Platform, Linking, Image, Touchable, TouchableOpacity, Pressable, Modal } from 'react-native';
import MapView, { Circle, Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { ClusterProps, MarkerClusterer } from '@teovilla/react-native-web-maps';
import type { Region } from 'react-native-maps';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import Icon from 'react-native-paper/src/components/Icon';
import { useClient } from '../../../../providers/ClientProvider';


function MyClusterComponent(props: ClusterProps<{ onPress(): void }>) {
  return (
    <Marker
      onPress={props.onPress}
      coordinate={props.coordinate}
      anchor={{ x: 0.5, y: 0.5 }}
    >
      <View style={styles.cluster}>
        <Text style={styles.clusterText}>{props.pointCountAbbreviated}</Text>
      </View>
    </Marker>
  );
}

export default function GeoMap(props: any) {
  const { clientName, clientAPIURL } = useClient();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [region, setRegion] = useState<Region | null>(null);
  const firstItem = props.data[0] && JSON.parse(props.data[0].Location);
  const mapRef = useRef<MapView>(null);

  const loadingFallback = useMemo(() => {
    return (
      <View>
        <Text>Loading</Text>
      </View>
    );
  }, []);
  const initialRegion = firstItem && firstItem.coords
    ? {
      latitude: firstItem.coords.latitude, // Use firstItem's latitude if available
      longitude: firstItem.coords.longitude, // Use firstItem's longitude if available
      latitudeDelta: 0.5, // Zoom level
      longitudeDelta: 0.5, // Zoom level
    }
    : {
      latitude: 24.7136, // Riyadh, Saudi Arabia
      longitude: 46.6753,
      latitudeDelta: 5, // Zoom out to show more area
      longitudeDelta: 5,
    };
  const GoToLocation = (latitude: any, longitude: any) => {
    try {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        //const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' });
        const scheme = Platform.select({ ios: 'comgooglemaps://0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${latitude},${longitude}`;
        const label = 'Observation Location';
        const url = Platform.select({
          ios: `${scheme}${label}@${latLng}`,
          android: `${scheme}${latLng}(${label})`
        });
        Linking.openURL(url);
      }
      else if (Platform.OS === 'web') {
        const googleMapLink = latitude && longitude && `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(googleMapLink, '_blank');
      }
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }
  let selectedObv = null;
  const selectedObservation = (obv: any) => {
    selectedObv = obv;
  }
  const GetMarker: any = (d: any) => {
    try {
      return d.map((item: any, index: number) => {
        const locationData = item.Location && JSON.parse(item.Location);
        let markerColor = item.ObservationType === "Good Practice/ Strong Point" ? "gray" : item.ObservationType === 'Findings' ? "yellow" : "salmon";
        //console.log(JSON.stringify(locationData));
        return (
          <Marker
            anchor={{ x: 0.5, y: 1 }}
            key={index}
            coordinate={{
              latitude: locationData.coords.latitude,
              longitude: locationData.coords.longitude,
            }}
            onPress={() => { setModalVisible(true); setModalContent(item) }}
          >
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 999,
                backgroundColor: markerColor,
                position: 'relative',
                zIndex: 19,
              }}
            >
              <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                  setModalVisible(!modalVisible);
                }}>
                <View style={[styles.centeredView]}>
                  <View style={styles.modalView}>
                    <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end', alignSelf: 'flex-end' }}>
                      <IconButton icon="close-circle-outline" iconColor={MD3Colors.error50} size={20} onPress={() => { setModalVisible(!modalVisible) }} />
                    </View>
                    {
                      modalContent &&

                      <View style={{ flexDirection: 'column', width: 400 }}>
                        {modalContent.packageName && <Text style={styles.modalText}>Package: {modalContent.packageName}</Text>}
                        <Text style={styles.modalText}>Type: {modalContent.ObservationType}</Text>
                        <Text style={styles.modalText}>Due: {modalContent.DueDate}</Text>
                        <Text style={styles.modalText}>Status: {modalContent.Status}</Text>
                        <Text style={styles.modalText}>Reference Number: {modalContent.ReferenceNumber}</Text>
                      </View>


                    }
                    <View style={{ flexDirection: 'row', gap: 20, padding: 20 }}>
                      {/* <Pressable
                            style={[styles.button, styles.buttonClose, ]}
                            onPress={() => { Linking.openURL(`${clientAPIURL}/site/greenport-dgda-ss01-TrainingObservations-EnvironmentalIssuesObservations/list?ID=569`, '_blank') }}>
                            <Text style={styles.textStyle}>View Observation</Text>
                          </Pressable> */}
                      <View></View>
                      <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => { GoToLocation(locationData.coords.latitude, locationData.coords.longitude) }}>
                        <Text style={styles.textStyle}>Go to Location</Text>
                      </Pressable>
                    </View>

                  </View>
                </View>
              </Modal>
              {/* <Tooltip title={item.packageName ? item.packageName + ", " + item.ObservationType + "," + item.DueDate:item.ObservationType + "," + item.DueDate}> */}
              <Pressable
                onPress={
                  //()=>GoToLocation(locationData.coords.latitude, locationData.coords.longitude)
                  () => { setModalVisible(true); setModalContent(item) }
                }>
                <Text>{item.ReferenceNumber ? item.ReferenceNumber : item.ID}</Text>
              </Pressable>
              {/* </Tooltip> */}

              <View
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 10,
                  backgroundColor: markerColor,
                  zIndex: 20,
                  bottom: -5,
                  alignSelf: 'center',
                  transform: [{ rotate: '45deg' }],
                }}
              ></View>
            </View>
          </Marker>
        )
      })
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }
  return (
    Platform.OS === 'web' ?
      <View style={styles.container}>
        <MapView
          ref={mapRef}
          provider={'google'}
          mapType={'satellite'}
          style={{ flex: 1 }}
          onRegionChange={setRegion}
          googleMapsApiKey={'AIzaSyA9JNlK2aGI9FyM8Iyv-bhdqK54ZPF7ruE'}
          //loadingFallback={loadingFallback}
          initialRegion={initialRegion}
        >
          <MarkerClusterer
            region={region}
            renderCluster={(cluster) => (
              <MyClusterComponent
                {...cluster}
                onPress={() =>
                  mapRef.current?.animateCamera({
                    center: cluster.coordinate,
                    zoom: cluster.expansionZoom + 3,
                  })
                }
              />
            )}
          >
            {props.data && GetMarker(props.data)}
          </MarkerClusterer>
        </MapView>
      </View>

      :
      <View style={styles.container}>
        <MapView
          ref={mapRef}
          provider={'google'}
          style={{ flex: 1 }}
          onRegionChange={setRegion}
          //loadingFallback={loadingFallback}
          initialRegion={initialRegion}
          mapType={'satellite'}
        >
          <MarkerClusterer
            region={region}
            renderCluster={(cluster) => (
              <MyClusterComponent
                {...cluster}
                onPress={() =>
                  mapRef.current?.animateCamera({
                    center: cluster.coordinate,
                    zoom: cluster.expansionZoom + 3,
                  })
                }
              />
            )}
          >
            {props.data && GetMarker(props.data)}
          </MarkerClusterer>
        </MapView>
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cluster: {
    backgroundColor: 'salmon',
    width: 20,
    height: 20,
    borderRadius: 999,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clusterText: {
    fontWeight: '700',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    //margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    //padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 8,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});