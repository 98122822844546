import React, { useEffect, useRef } from 'react';
import { View, Text, Animated, StyleSheet } from 'react-native';

interface ToastMessageProps {
  message: string;
  visible: boolean;
  onDismiss: () => void;
  indicator?:string;
}

const ToastMessageAnnimated: React.FC<ToastMessageProps> = ({ message, visible, onDismiss, indicator }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Opacity animation
  const slideAnim = useRef(new Animated.Value(50)).current; // Slide from bottom

  const bgColor = indicator && indicator === "success"? "rgb(75, 183, 95)" : indicator === "warning" ? "rgb(255,204,0)" : indicator === "fail"? "red": "rgba(0, 0, 0, 0.8)";
  const textColor = indicator === "warning"? "black" : '#fff';
  useEffect(() => {
    if (visible) {
      // Show toast: Fade in and slide up
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();

      // Auto-hide after 3 seconds
      const timer = setTimeout(() => {
        Animated.parallel([
          Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
          }),
          Animated.timing(slideAnim, {
            toValue: 50,
            duration: 300,
            useNativeDriver: true,
          }),
        ]).start(() => onDismiss()); // Hide after animation ends
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timeout on unmount
    }
  }, [visible]);

  if (!visible) return null; // Don't render if not visible

  return (
    <Animated.View
      style={[
        styles.toastContainer,
        { opacity: fadeAnim, transform: [{ translateY: slideAnim }], backgroundColor: bgColor? bgColor : "rgba(0, 0, 0, 0.8)"},
      ]}
    >
      <Text style={[styles.toastText, {color:textColor}]}>{message}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  toastContainer: {
    position: 'absolute',
    bottom: 50,
    left: 20,
    right: 20,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  toastText: {
    fontSize: 14,
  },
});

export default ToastMessageAnnimated;
