import React, { useMemo } from 'react';
import { View, StyleSheet, Text, Dimensions, ScrollView } from 'react-native';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLegend, VictoryLine, VictoryVoronoiContainer, VictoryStack } from 'victory-native';

interface StackedBarChartProps {
  label: string[];
  data: any;
  ChartTitle: string;
  subTitle?: string;
  legend?: any;
  threshold?: number;
}

function StackedBarChartComp(props: StackedBarChartProps) {
  const screenWidth = Dimensions.get('window').width;
  const chartWidth = Math.max(screenWidth / 3, props.data.labels.length * 20); // Dynamic width with minimum limit

  const transformedDatasets = useMemo(() => {
    return props.data.datasets.map((dataset: any) => ({
      data: dataset.data.map((value: any) => ({
        x: value.x, // Use the date or label for the x-axis
        y: parseFloat(value.y.toFixed(2)), // Value for the bar
      })),
      color: dataset.color, // Preserve color function
    }));
  }, [props.data]);

  return (
    <View style={[styles.container]}>
      {props.data.datasets.length === 0 ? (
        <Text style={styles.noDataText}>{`No data available for ${props.ChartTitle}`}</Text>
      ) : (
        <ScrollView horizontal>
          <VictoryChart
            width={chartWidth}
            domainPadding={
              props.data.labels.length > 10
                ? { x: 10 }
                : props.data.labels.length > 4
                  ? { x: 50 }
                  : { x: 70 }
            }
            padding={{ top: 50, bottom: 100, left: 80, right: 50 }}
            containerComponent={
              <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}: ${datum.y.toFixed(2)}`} />
            }
          >
            {/* Legend */}
            <VictoryLegend
              x={10}
              y={20}
              orientation="horizontal"
              gutter={20}
              data={props.data.datasets.map((dataset: any, index: number) => ({
                name: dataset.name, // Exceedances or Non-Exceedances
                symbol: { fill: dataset.color ? dataset.color(1) : '#000' }, // Assign colors
              }))}
            />

            {/* X-Axis */}
            <VictoryAxis
              tickFormat={(t) => (t.length > 10 ? `${t.slice(0, 8)}...` : t)}
              style={{
                tickLabels: {
                  fontSize: 10,
                  angle: props.data.labels.length > 5 ? 60 : 45,
                  textAnchor: 'start',
                },
              }}
            />

            {/* Y-Axis */}
            <VictoryAxis dependentAxis tickFormat={(t) => `${t.toFixed(2)}`} />
            {/* Threshold Line */}
            {props.threshold && (
              <VictoryLine
                style={{
                  data: { stroke: 'red', strokeDasharray: '5,5' },
                }}
                data={[
                  { x: props.data.labels[0], y: props.threshold }, // Start of line
                  { x: props.data.labels[props.data.labels.length - 1], y: props.threshold }, // End of line
                ]}
              />
            )}

            {/* Stacked Bars */}
            <VictoryStack colorScale={props.data.datasets.map((dataset: any) => dataset.color ? dataset.color(1) : '#000')}>
              {transformedDatasets.map((dataset: any, index: any) => (
                <VictoryBar
                  key={index}
                  data={dataset.data}
                  // barWidth={({ index }) => {
                  //   const maxBarWidth = 20;
                  //   const minBarWidth = 3;
                  //   const calculatedWidth = chartWidth / props.data.labels.length / 3;
                  //   return Math.max(minBarWidth, Math.min(maxBarWidth, calculatedWidth));
                  // }}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 1000 },
                  }}
                />
              ))}
            </VictoryStack>
          </VictoryChart>
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  //container: { padding: 16, justifyContent:'center', alignContent:'center', alignSelf:'center' },
  container: { padding: 16 },
  noDataText: { fontSize: 16, textAlign: 'center', color: '#999' },
});

export default StackedBarChartComp;
