import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import PieChartComp from '../common/charts/piChart-builder';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
import { Loader } from '../../Common/Loader';

interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?: number;
  projectId:string;
}
export const DbVibrationLog: React.FC<LogProps> = ({ logTitle, accessToken, clientAPIURL, filterVal, projectId }) => {
  const [vibrationLogData, setLogData] = useState<null | any>(null);
  const [vibrationLogColumns, setLogColumns] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken, projectId);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ✅ Always update loading state
      }
    }
    fetchData();
  }, []);
  if (isLoading) {
    return <Loader />; // ✅ Show a loading indicator while fetching
  }

  return vibrationLogData && vibrationLogColumns?.length > 0 ? (
    <CreateNoiseLogCharts filterVal={filterVal} setVibrationLogData={setLogColumns} vibrationLogData={vibrationLogData} vibrationLogColumns={vibrationLogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} />
  ) : (
    <View>
      <PieChartComp
        data={[]}
        colorPallate={interpolateBlues}
        subTitle="(Cumulative)"
        ChartTitle="Vibrations"
      />
    </View>
  )
}
export default DbVibrationLog;

interface CreateNoiseLogChartsProps {
  vibrationLogData: any;
  vibrationLogColumns: any;
  logTitle: string;
  clientAPIURL: string;
  setVibrationLogData: any;
  filterVal?: number;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ filterVal, setVibrationLogData, vibrationLogData, vibrationLogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const ColumnsWithIDColumn = vibrationLogColumns && [
    ...vibrationLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  //const sortedData = vibrationLogData//[...vibrationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const currentDate = new Date();  // Get the current date
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

  const dbFilteredData = filterVal === 0 ? vibrationLogData : vibrationLogData.filter((item: any) => {
    // Assuming item.Date is a JavaScript Date object
    const itemDate = item.Date && new Date(item.Date);
    return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
  });
  const updatedData = dbFilteredData.map((item: any) => {
    let conformity = "";
    if (item.MonitoringResultsMaxPPVmms != "") {
      conformity = item.MonitoringResultsMaxPPVmms <= item.ComplianceLimitmms ? "Conformity" : "Non Conformity";
    }
    const Conformity = conformity
    const pieKey = Conformity;
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Conformity, Period, key, Date, pieKey };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const datasets = Object.values(lineChartData).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets
  };

  const conformityTypes = new Set();
  conformityTypes.add('Conformity');
  conformityTypes.add('Non Conformity');

  const legendData1 = [...conformityTypes].map((type: any, index: any) => ({
    name: type,
  }));
  const periods = [...new Set(newData.map(item => item.Period))];

  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalConfirmity: any = newData.filter(item => item.Conformity === "Conformity").length;
    const TotalNonConfirmity: any = newData.filter(item => item.Conformity === "Non Conformity").length;
    const itemCount = parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity);//filteredItems.length;
    const sumMonitoringResults = filteredItems.reduce((sum, item) => sum + item.MonitoringResultsMaxPPVmms, 0);
    const Average = sumMonitoringResults / itemCount;
    const Maximum = Math.max(...filteredItems.map(item => item.MonitoringResultsMaxPPVmms));
    const Lower = 3;
    const Higher = 10;
    const ConformityPercentage = ((parseFloat(TotalConfirmity) / (parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity))) * 100).toFixed(2)
    const NonConformityPercentage = ((parseFloat(TotalNonConfirmity) / (parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity))) * 100).toFixed(2)

    return { "Period": Period, "Number Of Results": itemCount, "Average": Average, "Maximum": Maximum, "Lower": Lower, "Higher": Higher, "Confirmity": TotalConfirmity, "Confirmity Percentage": ConformityPercentage, "Non Conformity": TotalNonConfirmity, "Non Confirmity Percentage": NonConformityPercentage };
  });
  const columnKeys = filteredDataByPeriod[0] && Object.keys(filteredDataByPeriod[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  });


  const pieDataTotal: any = {};
  updatedData.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataTotal[Key]) {
      pieDataTotal[Key] += 1;
    } else {
      pieDataTotal[Key] = 1;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byConformityPieData = Object.keys(pieDataTotal).map((key, index) => ({
    name: key,
    value: pieDataTotal[key],
    color: colorType5(index.toString()),//colorScale2(pieDataTotal[key] / Math.max(...Object.values(pieDataTotal) as number[])),
  }));

  const lineChartDataSum: number[] = [];
  const lineChartDataAverage: number[] = [];

  uniquePeriod.forEach((period) => {
    const periodLength = newData.filter((item) => item.Period === period).length;
    const filteredDataSum = newData
      .filter((item) => item.Period === period)
      .reduce((accumulator, currentValue) => accumulator + currentValue.MonitoringResultsMaxPPVmms, 0);
    const filteredDataAverage = newData
      .filter((item) => item.Period === period)
      .reduce((accumulator, currentValue) => accumulator + currentValue.MonitoringResultsMaxPPVmms, 0) / periodLength;

    lineChartDataSum.push(filteredDataSum);
    lineChartDataAverage.push(filteredDataAverage);
  });

  const lineChartDataLevel = {
    Line1: lineChartDataSum,
    Line2: lineChartDataAverage
  };

  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const datasets3 = Object.values(lineChartDataLevel).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataLevelChart = {
    labels: uniquePeriod,
    datasets: datasets3
  };
  const BaseLineTypes = new Set();
  BaseLineTypes.add('Maximum');
  BaseLineTypes.add('Average');
  const legendData2 = [...BaseLineTypes].map((type: any, index: any) => ({
    name: type,
  }));

  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = updatedData.length;
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Vibration Monitoring Results'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View style={CommonStyles.container}>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Vibration Results"} /></View>
        {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Monthly by Conformity)'} ChartTitle={"Vibration Results"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineDataLevelChart} label={uniquePeriod} subTitle={'(Monthly by Average)'} ChartTitle={"Total"} /></View> */}
      </View>
    </View>
  );
};