import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import PieChartComp from '../common/charts/piChart-builder';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
import { Loader } from '../../Common/Loader';
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?: number;
  projectId:string;
}
export const DbWasteLog: React.FC<LogProps> = ({ logTitle, accessToken, clientAPIURL, filterVal, projectId }) => {
  const [wasteLogData, setLogData] = useState<null | any>(null);
  const [wasteLogColumns, setLogColumns] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken, projectId);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ✅ Always update loading state
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />; // ✅ Show a loading indicator while fetching
  }

  return wasteLogData && wasteLogColumns?.length > 0 ? (
    <CreateLogCharts filterVal={filterVal} setWasteLogData={setLogData} wasteLogData={wasteLogData} wasteLogColumns={wasteLogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} />
  ) : (
    <View>
      <PieChartComp
        data={[]}
        colorPallate={interpolateBlues}
        subTitle="(Cumulative)"
        ChartTitle="Waste Tracking"
      />
    </View>
  )
}
export default DbWasteLog;
interface CreateLogChartsProps {
  wasteLogData: any;
  wasteLogColumns: any;
  logTitle: string;
  clientAPIURL: string;
  setWasteLogData: any;
  filterVal?: number;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ filterVal, setWasteLogData, wasteLogData, wasteLogColumns, logTitle, clientAPIURL }) => {
  const ColumnsWithIDColumn = wasteLogColumns && [
    ...wasteLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  //const sortedData = wasteLogData//[...wasteLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const currentDate = new Date();  // Get the current date
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

  const dbFilteredData = filterVal === 0 ? wasteLogData : wasteLogData.filter((item: any) => {
    // Assuming item.Date is a JavaScript Date object
    const itemDate = item.Date && new Date(item.Date);
    return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
  });
  const updatedData = dbFilteredData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const Hazardous: string = checkHazardous(item.WasteType);
    const Diverted: string = checkDiverted(item.WasteDisposalMethod);

    return { ...item, Period, key, Date, Hazardous, Diverted };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }
  function checkDiverted(div: string) {
    //let val;
    switch (div) {
      case "Reuse on-site": return "Diverted";
      case "Reuse off-site": return "Diverted";
      case "Recycling / Recover": return "Diverted";
      case "Landfill": return "Not Diverted";
      case "Dump site": return "Not Diverted";
      case "Other": return "Not Diverted";
      default: return null
    }
  }

  function checkHazardous(wT: string) {
    //let val;
    switch (wT) {
      case "Paper/ Cardboard": return "Non Hazardous";
      case "Plastic": return "Non Hazardous";
      case "Metal": return "Non Hazardous";
      case "Wood": return "Non Hazardous";
      case "Green": return "Non Hazardous";
      case "Concrete/ Inert debris": return "Non Hazardous";
      case "Asphalt": return "Non Hazardous";
      case "General Domestic/ Food waste": return "Non Hazardous";
      case "General Construction/ Mixed Waste": return "Non Hazardous";
      case "Electronic (without hazardous materials)": return "Non Hazardous";
      case "Other (non hazardous)": return "Non Hazardous";

      case "Electronic (containing hazardous materials)": return "Hazardous";
      case "Used oil": return "Hazardous";
      case "Used oil filters": return "Hazardous";
      case "Contaminated material (including contaminated pads, rags, gloves and soil)": return "Hazardous";
      case "Batteries": return "Hazardous";
      case "Medical": return "Hazardous";
      case "Other (hazardous)": return "Hazardous";
      default: return null
    }
  }


  const pieData: any = {};

  updatedData.forEach((item: any) => {
    const wasteCategory = item.WasteCategory;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData[wasteCategory]) {
      pieData[wasteCategory] += quantityTonne;
    } else {
      pieData[wasteCategory] = quantityTonne;
    }
  });
  const colorScale1 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byWasteCategoryPieData = Object.keys(pieData).map((wasteCategory, index) => ({
    name: wasteCategory,
    value: pieData[wasteCategory],
    color: colorType5(index.toString()),//colorScale1(pieData[wasteCategory] / Math.max(...Object.values(pieData) as number[])),
  }));

  //const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  //const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  //const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  //const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  //const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  //const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");


  const TotalCount = updatedData.reduce((total: any, item: any) => {
    if (item.QuantityTonne) {
      return total + item.QuantityTonne;
    }
    return total;
  }, 0);
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Waste (Tonnes)'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View>
        <View><PieChartComp data={byWasteCategoryPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Waste Qty/Type"} /></View>
        {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataByType} label={uniquePeriod} subTitle={'(Monthly - m3)'} ChartTitle={"Waste Qty/Type"} /></View>
            <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byWasteDisposalMethodPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Waste Qty/Destination"} /></View>
            <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData} label={uniquePeriod} subTitle={'(Monthly - m3)'} ChartTitle={"Waste Qty/Type"} /></View>
            <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byTypeNonHazardousPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Non Hazardous Waste Qty/Type"} /></View>
            <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byTypeHazardousPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Hazardous Waste Qty/Type"} /></View>
            <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byDivertedPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Waste Diversion"} /></View> */}
      </View>

    </View>
  );
};