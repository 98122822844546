import React, { useState, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import PaperForm from '../forms/PaperForm';

import DT from './DataTable';
import WebObservationForm from '../forms/WebObservationForm';
import WasteTrackingForm from '../forms/WasteTrackingForm';
import ReportRequestForm from '../forms/ReportRequestForm';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import { getAllItems } from '../service/dataOperations';

interface Props {
  data: Array<any>;
  columns: Array<any>;
  siteId: string;
  logTitle: string;
  updateData: (item: Array<any>) => void;
  isConsolidatedTable: boolean;
  Permissions?: string;
  QueryStringID?: string;
  chartVisibility?: any;
  funcSetShowFilter?: any;
  setRefreshData?: (e: boolean) => void;
  selectedYear?:string;
}
const PaperTableDynamic: React.FC<Props> = (props) => {

  //let data = props.data; // Use directly to ensure updates
  const [data, setData] = useState(props.data);
  const sortedData = useMemo(() => {
    return data ? [...data].sort((a, b) => b.ID - a.ID) : [];
  }, [props.data, data]);

  const columns = useMemo(() => {
    return props.columns
      .filter(column => column.columnName !== "Title" && column.columnName !== "SPID" && column.columnName !== "Semester")
      .sort((a, b) => a.order - b.order);
  }, [props.columns]);

  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [formMode, setformMode] = useState<string>('');
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  
  const formColumns = {
    columns: props.columns.sort((a, b) => a.order - b.order).map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
        multiline: column.multiline,
        hidden: column.hidden,
      }
    }).filter((column) => column !== null),
  };
useEffect(() => {
  setSelectedRows([]);
}, [props.data]);
  useEffect(() => {
    if (props.logTitle === "Environmental Issues Observations" && props.QueryStringID) {
      const itemId = Number(props.QueryStringID);
      if (itemId > 0) {
        handleQueryStringItem(itemId);
      }
    }
  }, [props.QueryStringID, props.logTitle]);

  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
  
        const updatedData = json.items && json.items
          ?.sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date))
          .map((item: any) => ({
            ...item,
            key: item.ID,
            Date: item.Date ? returnDate(item.Date) : null,
            DueDate: item.DueDate ? returnDate(item.DueDate) : null,
            DateClosedOut: item.DateClosedOut ? returnDate(item.DateClosedOut) : null,
            Status: item.Status && item.Status === "Escalated" ? "Escalated" : checkStatus(item.ClosedOut),
            ReferenceNumber: item.ReferenceNumber ? `${item.ReferenceNumber}-${item.ID}` : item.ID,
          })) || props.data;
  
        setData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    if (!showPanel && !props.isConsolidatedTable) {
      fetchData();
    }
  }, [showPanel]);
  

  function checkStatus(div: string) {
    switch (div) {
      case "Yes": return "Closed";
      case "No": return "Open";
      case "Escalated": return "Escalated";
      default: return null
    }
  }
  function returnDate(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      console.log(formattedDate)
      return formattedDate;
    } else {
      return null;
    }
  }
  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    //setShowConfirmation(false);
  };

  function handleToastDismiss() {
    //setToastVisibility(false);
  };
  function updateFormState() {
    setShowPanel(!showPanel);
    props.funcSetShowFilter(showPanel);
  }
  function updateFormMode(val: string) {
    setformMode(val);
    if (val === 'New') {
      updateFormState();
    }
  }
  function ItemCreated(item: Array<any>) {

    props.updateData(item);
    updateFormState();
  }
  function handleRowPress(row: any) {
    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };
  function handleQueryString(itemID: any) {
    //debugger;
    const rowId: number = parseInt(itemID);
    setSelectedRows([rowId]);
    // if (selectedRows.includes(rowId)) {
    //   setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    // } else {
    //   setSelectedRows([...selectedRows, rowId]);
    // }
  };

  function handleQueryStringItem(sRows:number) {
    const selectedItem = data.find(item => item.ID === sRows);
    if (!selectedItem) return;
  
    setSelectedRows([selectedItem]);
    setformMode("View");
    setShowPanel(true);
    props.funcSetShowFilter(true); // Explicitly setting, not relying on old state
  };
  function _handleQueryStringItem(sRows: number) {
    const selectedItem = data.filter(item => item.ID === sRows);
    setSelectedRows(selectedItem);
    setformMode("View");
    //updateFormState();
    setShowPanel(!showPanel);
    props.funcSetShowFilter(showPanel);
  };
  function ViewPressAction(sRows: any) {
    const selectedItem = data && data.filter(item => item.ID === sRows[0]);
    selectedItem && setSelectedRows(selectedItem);
    updateFormState();

  };
  function _ViewPressAction(sRows:any) {
    const selectedItem = data.find(item => item.ID === sRows[0]);
    if (!selectedItem) return;
  
    setSelectedRows([selectedItem]); // Ensure it's an array of numbers
    updateFormState();
  };
  function EditPressAction(sRows: any) {
    const selectedItem = data.filter(item => item.ID === sRows[0]);
    setSelectedRows(selectedItem);
    updateFormState();

  };
  function NewPressAction() {
    updateFormState();
  };
  function chartVisibility(val: boolean) {
    props.chartVisibility(val)
    setShowPanel(false);
    props.funcSetShowFilter(true);
  }
  

  return (
    <View style={{ flex: 1 }}>
      {showPanel === true ?
        (
          props.logTitle === "Environmental Issues Observations" ?
            <WebObservationForm
              fields={formColumns.columns}
              formMode={formMode}
              selectedRow={selectedRows}
              closeForm={updateFormState}
              siteId={props.siteId}
              logTitle={props.logTitle}
              updateFormState={updateFormState}
              ItemCreated={ItemCreated}
              Permissions={props.Permissions}
              chartVisibility={chartVisibility}
            />
            :
            props.logTitle === "Waste Tracking Log" ?

              <WasteTrackingForm
                fields={formColumns.columns}
                formMode={formMode}
                selectedRow={selectedRows}
                closeForm={updateFormState}
                siteId={props.siteId}
                logTitle={props.logTitle}
                updateFormState={updateFormState}
                ItemCreated={ItemCreated}
              />
              :
              props.logTitle === "Report Request" ?

                <ReportRequestForm
                  fields={formColumns.columns}
                  formMode={formMode}
                  selectedRow={selectedRows}
                  closeForm={updateFormState}
                  siteId={props.siteId}
                  logTitle={props.logTitle}
                  updateFormState={updateFormState}
                  ItemCreated={ItemCreated}
                />
                :
                <PaperForm
                  fields={formColumns.columns}
                  formMode={formMode}
                  selectedRow={selectedRows}
                  closeForm={updateFormState}
                  siteId={props.siteId}
                  logTitle={props.logTitle}
                  updateFormState={updateFormState}
                  ItemCreated={ItemCreated}
                />
        )
        :
        (
          data &&
          <DT
            siteId={props.siteId}
            logTitle={props.logTitle}
            data={sortedData}
            columns={columns}
            updateFormState={updateFormState}
            updateFormMode={updateFormMode}
            handleRowPress={handleRowPress}
            ViewPressAction={ViewPressAction}
            EditPressAction={EditPressAction}
            NewPressAction={NewPressAction}
            isConsolidatedTable={props.isConsolidatedTable}
            setRefreshData={props.setRefreshData}
            selectedYear = {props.selectedYear}
          />
        )}
    </View>
  );
};

export default PaperTableDynamic;
