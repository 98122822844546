import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Pressable } from 'react-native';
import { DatePickerInput } from 'react-native-paper-dates';
import { GetFencelineDailyData } from '../../ConsolidatedLogs/ConsolidatedController';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { logStyles } from '../../LogControllers/LogStyles';
import { Tabs, TabScreen, TabsProvider } from 'react-native-paper-tabs';
import FencelinePM10 from './Fenceline_PM10';
import FencelinePM2P5 from './Fenceline_PM2P5';
import FencelineVibration from './Fenceline_Vibration';
import FencelineNoise from './Fenceline_Noise';
import FencelineWind from './Fenceline_Wind';

interface Props {
    siteId: string;
    logTitle: string;
}
interface StationData {
    stationName: string;
    recordedDate: string;
    pM10: number;
}

export default function FencelineCharts(props: Props) {
    const [dailyData, setDailyData] = useState<any>([]);
    const [sixMonthDailyData, setsixMonthDailyData] = useState<StationData[]>([]);
    // const [startDate, setStartDate] = useState<Date | null>(new Date('2025-01-01'));
    // const [endDate, setEndDate] = useState<Date | null>(new Date('2025-01-31'));
    const [startDate, setStartDate] = useState<Date | null>(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
    });
    const [pm10Threshold, setPm10Threshold] = useState<number>(340);
    const [pm2P5Threshold, setPm2P5Threshold] = useState<number>(34);
    const [noiseDayThreshold, setNoiseDayThreshold] = useState<number>(70);
    const [noiseNightThreshold, setNoiseNightThreshold] = useState<number>(60);
    const [vibrationThreshold, setVibrationThreshold] = useState<number>(2.5);

    const [endDate, setEndDate] = useState<Date | null>(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
    });
    const [loading, setLoading] = useState(false);
    const { getAccessToken } = useAuth();
    const { clientAPIURL } = useClient();
    const fetchData = async () => {
        try {
            setLoading(true);
            const formattedStartDate = startDate?.toISOString().split('T')[0];
            const formattedEndDate = endDate?.toISOString().split('T')[0];
            const startDate6Month = new Date(endDate!);
            startDate6Month.setDate(1); // Set to the first day of the month
            startDate6Month.setMonth(startDate6Month.getMonth() - 6);
            const formattedStartDate6Month = startDate6Month.toISOString().split('T')[0];
            const accessToken = await getAccessToken();
            const data = await GetFencelineDailyData(accessToken, clientAPIURL, formattedStartDate, formattedEndDate);
            const sixMonthsData = await GetFencelineDailyData(accessToken, clientAPIURL, formattedStartDate6Month, formattedEndDate);
            console.log('Fetched Data:', data);
            data && setDailyData(data);
            sixMonthsData && setsixMonthDailyData(sixMonthsData);
            //processChartData(data);
            //const multiBarChartData = processMultiBarChartData(data); // Call the new processor
            //setPM10DailyChartData(multiBarChartData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData(); // Fetch initial data on component mount
    }, []);
    const groupDataByDate = (data: StationData[]) => {
        return data.reduce((acc: Record<string, StationData[]>, item: StationData) => {
            const date = item.recordedDate.split("T")[0]; // Extract date
            if (!acc[date]) acc[date] = [];
            acc[date].push(item);
            return acc;
        }, {});
    };
    const processChartData = (data: any) => {
        // Group data by date
        const groupedByDate: Record<string, StationData[]> = data.reduce((acc: Record<string, StationData[]>, item: StationData) => {
            const date = item.recordedDate.split("T")[0]; // Extract date
            if (!acc[date]) acc[date] = [];
            acc[date].push(item);
            return acc;
        }, {});

        // Extract labels (dates)
        const pm10Data: any = { MaxPM10: [] };
        const labels: string[] = [];
        Object.entries(groupedByDate).forEach(([date, items]) => {
            const maxPM10 = Math.max(...items.map((item: any) => item.pM10));
            pm10Data["MaxPM10"].push(maxPM10);
            labels.push(date);
        });
        // Prepare datasets for GroupBarChartsComp
        const colorScaleBar1 = scaleOrdinal(schemeCategory10);
        const BarDatasets1 = Object.entries(pm10Data).map(([key, values], index: any) => ({
            data: values,
            strokeWidth: 2,
            color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
        }));
        const PM10MaxBar = {
            labels, // Dates
            datasets: BarDatasets1,
        };
        //setChartData(PM10MaxBar);
    };
    const processMultiBarChartData = (data: StationData[]) => {
        // Group data by date
        const groupedByDate = groupDataByDate(data);
        const uniqueStations = Array.from(new Set(data.map((item) => item.stationName)));
        // Extract labels (dates) and prepare datasets
        const labels: string[] = Object.keys(groupedByDate); // Unique dates
        const colorScaleBar1 = scaleOrdinal(schemeCategory10);
        const multiBarDatasets = uniqueStations.map((stationName, index: any) => ({
            name: stationName, // Group name for the station
            data: labels.map((date) => {
                const items = groupedByDate[date];
                const stationData = items.find((item) => item.stationName === stationName);
                return {
                    x: date, // Date
                    y: stationData ? stationData.pM10 : 0, // PM10 value or 0 if no data
                };
            }),
            strokeWidth: 2,
            color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
        }));
        return {
            labels, // Dates
            datasets: multiBarDatasets, // Data for each station per date
        };
    };
    return (
        <View>
            <View style={styles.row}>
                <View style={styles.flexItem}>
                    <Text style={styles.label}>Start Date:</Text>
                    <DatePickerInput
                        locale="en-gb"
                        label={null}
                        value={startDate}
                        onChange={(date: any) => setStartDate(date)}
                        inputMode="start"
                        style={styles.datePicker}
                    />
                </View>
                <View style={styles.flexItem}>
                    <Text style={styles.label}>End Date:</Text>
                    <DatePickerInput
                        locale="en-gb"
                        label={null}
                        value={endDate}
                        onChange={(date: any) => setEndDate(date)}
                        inputMode="end"
                        style={styles.datePicker}
                    />
                </View>
                <View style={{}}>
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => { fetchData() }}>
                        <Text style={styles.textStyle}>Show Dashboard</Text>
                    </Pressable>
                </View>
            </View>
            <TabsProvider defaultIndex={0}>
                <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
                    <TabScreen label="PM10" icon="air-filter" >
                        <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                            <View>
                                <ScrollView contentContainerStyle={styles.container}>
                                    {dailyData.length > 0 ? (
                                        <View>
                                            <FencelinePM10 data={dailyData} sixMonthData={sixMonthDailyData} threshold={pm10Threshold} />
                                        </View>
                                    ) : (
                                        <Text style={styles.noDataText}>
                                            {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                        </Text>
                                    )}
                                </ScrollView>
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="PM2.5" icon="air-filter" >
                        <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                            <View>
                                <ScrollView contentContainerStyle={styles.container}>
                                    {dailyData.length > 0 ? (
                                        <View>
                                            <FencelinePM2P5 data={dailyData} sixMonthData={sixMonthDailyData} threshold={pm2P5Threshold} />
                                        </View>
                                    ) : (
                                        <Text style={styles.noDataText}>
                                            {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                        </Text>
                                    )}
                                </ScrollView>
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Wind" icon="weather-windy" >
                        <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                            <View>
                                <View style={logStyles.container}>
                                    <View style={logStyles.cardThree}>
                                    <ScrollView contentContainerStyle={styles.container}>
                                    {dailyData.length > 0 ? (
                                        <View>
                                            <FencelineWind data={dailyData} />
                                        </View>
                                    ) : (
                                        <Text style={styles.noDataText}>
                                            {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                        </Text>
                                    )}
                                    {sixMonthDailyData.length > 0 ? (
                                        <View>
                                            <FencelineWind data={sixMonthDailyData} />
                                        </View>
                                    ) : (
                                        <Text style={styles.noDataText}>
                                            {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                        </Text>
                                    )}
                                </ScrollView>
                                    </View>
                                </View>
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Noise" icon="surround-sound" >
                        <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                            <View>
                                <View style={logStyles.container}>
                                    <View style={logStyles.cardThree}>
                                        {dailyData.length > 0 ? (
                                            <View>
                                                <FencelineNoise data={dailyData} sixMonthData={sixMonthDailyData} thresholdDay={noiseDayThreshold} thresholdNight={noiseNightThreshold} />
                                            </View>
                                        ) : (
                                            <Text style={styles.noDataText}>
                                                {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                            </Text>
                                        )}
                                    </View>
                                </View>
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Vibration" icon="vibrate" >
                        <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                            <View>
                                <ScrollView contentContainerStyle={styles.container}>
                                    {dailyData.length > 0 ? (
                                        <View>
                                            <FencelineVibration data={dailyData} sixMonthData={sixMonthDailyData} threshold={vibrationThreshold} />
                                        </View>
                                    ) : (
                                        <Text style={styles.noDataText}>
                                            {loading ? 'Loading data...' : 'No data available for the selected range.'}
                                        </Text>
                                    )}
                                </ScrollView>
                            </View>
                        </ScrollView>
                    </TabScreen>
                </Tabs>
            </TabsProvider>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    flexItem: {
        flexBasis: '30%', // Adjust to control the size of each item in the row
        marginBottom: 8, // For smaller screens when wrapping
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    datePicker: {
        width: '100%',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#4BB75F',
    },
    buttonClose: {
        backgroundColor: '#4BB75F',
    },
    dataContainer: {
        marginTop: 16,
    },
    dataRow: {
        padding: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
    },
    noDataText: {
        marginTop: 16,
        fontSize: 16,
        fontStyle: 'italic',
        color: '#999',
    },

    title: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 16,
    },
});
