import React, { useState, useEffect, useMemo } from "react";
import { View, Text, TouchableOpacity, StyleSheet, ActivityIndicator, ScrollView, Platform } from "react-native";
import { Checkbox, DataTable } from "react-native-paper";
import ConfirmBox from "../../Common/ConfirmBox";
import { useClient } from "../../../providers/ClientProvider";
import { getData, createRecord, updateRecord } from "../common/service/ProjectRecords";
import { useAuth } from "../../../providers/AuthProvider";
import CreateFolderModal from "./ProjectRecordFolderModal";
import ProjectRecordUploadModal from "./ProjectRecordUploadModal";
import ToastMessageAnnimated from "./Toast";
import { logStyles } from "./LogStyles";
import { formatDateToLocal } from "../common/service/dataOperations";

type FolderItem = {
  name: string;
  type: "folder" | "file" | "library";
  id?: number;
  created?: string;
  modified?: string;
  deleted: number; // New property to track soft deletion status
  children?: FolderItem[]; // Only folders have children
  parentId: any,
  blobId?: string;
  listId?: string;
};
type ProjectRecord = {
  name: string;
  type: "folder" | "file" | "library";
  id?: number;
  created?: string;
  modified?: string;
  deleted: number; // New property to track soft deletion status
  children?: FolderItem[]; // Only folders have children
  parentId: any,
  blobId?: string;
  listId?: string;
};
export default function ProjectRecordsDataTable(props: any) {
  const { getAccessToken } = useAuth();
  const { clientAPIURL } = useClient();
  const [currentListId, setListId] = useState("");
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showUpdateFolderModal, setUpdateShowFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [breadcrumbPath, setBreadcrumbPath] = useState<FolderItem[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<FolderItem | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toastIndicator, setToastIndicator] = useState("info")
  const [visibleItems, setVisibleItems] = useState([]); // Items to display
  const [page, setPage] = useState<number>(1); // Current page
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // To check if more data is available
  const [sortConfig, setSortConfig] = useState({
    column: "dateModified", // Default sorting column
    order: "desc", // Default sorting order
  });
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // Store selected file IDs
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const PAGE_SIZE = 30;

  const [records, setRecords] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleUploadClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  function buildHierarchy(flatData: ProjectRecord[]) {
    // Create a map to hold references to each item by its ID
    const map = new Map();
    let rootItem: ProjectRecord | null = null;
    // First pass: Initialize all items in the map with their children arrays
    flatData.forEach((item: ProjectRecord) => {
      map.set(item.id, { ...item, children: [] });
      if (item.type === 'library') {
        rootItem = map.get(item.id); // Set the root item
      }
    });
    if (!rootItem) {
      console.error("No root item found with type 'library'.");
    }
    // Second pass: Assign children to their respective parents
    flatData.forEach((item: ProjectRecord) => {
      if (item.parentId && item.type !== 'library') {
        const parent = map.get(item.parentId);
        if (parent) {
          parent.children.push(map.get(item.id));
        } else {
          console.warn(`Parent with ID ${item.parentId} not found for item`, item);
        }
      }
    });
    return map.get(rootItem.id); // Return the root item with its complete hierarchy
  }

  // useEffect(() => {
  //   if (currentFolder) {
  //     setBreadcrumbPath([currentFolder]);
  //     debugger;
  //     console.log(`useEffect ${currentFolder}`);
  //   }
  // }, [currentFolder]);
  useEffect(() => {
    if (breadcrumbPath.length === 0 && currentFolder) {
      // Set the breadcrumb path only if it’s empty
      setBreadcrumbPath([currentFolder]);
    }
  }, [currentFolder]);
  
  useEffect(() => {
    if (breadcrumbPath.length > 0) {
      loadSortedData(1); // Load data whenever breadcrumbPath changes
    }
  }, [breadcrumbPath]);
  //const selectedFolder = breadcrumbPath[breadcrumbPath.length - 1];
  

  useEffect(() => {
    const loadRecords = async () => {
      if (props.listObj?.title === "Project Records") {
        setListId(props.listObj.id);
        if (props.listObj.id) await fetchRecords(props.listObj.id);
      }
    };
    loadRecords();
  }, [props.listObj]);

  const fetchRecords = async (oListId: string, folderIdToShow?: number, previousBreadcrumbIds: number[] = []) => {
    setLoading(true);
    setError("");
    try {
      const endpoint = `${clientAPIURL}api/ProjectRecords/${oListId}`;
      const accessToken = await getAccessToken();
      const response = await getData(endpoint, accessToken)

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      const flatData = await response.json();
      const formattedData = flatData.map((item: any) => ({
        ...item,
        created: formatDateToLocal(item.created),
        modified: formatDateToLocal(item.modified),
      }));

      const hierarchicalData = buildHierarchy(formattedData);
      setRecords(formattedData); // Set retrieved records to the state

      // if (hierarchicalData.children.length > 0) {
      //   setCurrentFolder(hierarchicalData);
      // }

      const folderToDisplay = folderIdToShow
        ? findFolderById(hierarchicalData, folderIdToShow)  // If folder ID specified, find it
        : hierarchicalData;

      if (folderToDisplay) {
        console.log(`After fetched and formatted ${currentFolder}`);
        setCurrentFolder(folderToDisplay);
        setVisibleItems(folderToDisplay.children || []);

        //const newBreadcrumbPath = buildBreadcrumbPath(hierarchicalData, folderToDisplay.id);
        const newBreadcrumbPath = previousBreadcrumbIds.map(id => findFolderById(hierarchicalData, id)).filter(folder => folder);
        setBreadcrumbPath(newBreadcrumbPath);
      }
    } catch (err) {
      setError("Failed to fetch project records.");
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  

  const findFolderById = (folder: any, folderId: number): any => {
    if (folder.id === folderId) {
      return folder;
    }
    if (folder.children) {
      for (let child of folder.children) {
        const found = findFolderById(child, folderId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };
  
  const navigateToBreadcrumb = (index: number) => {
    setBreadcrumbPath(breadcrumbPath.slice(0, index + 1));
    //const updatedPath = breadcrumbPath.slice(0, index + 1);
    //const currentFolder = updatedPath[updatedPath.length - 1];
    //setVisibleItems(currentFolder.children || []);


    setPage(1);
    setHasMore(true);
    setSelectedItems([]);
    //fetchRecords(props.listObj.id, currentFolder.id);
  };

  const openFolder = (folder: any) => {
    if (folder.type === "folder") {
      setBreadcrumbPath([...breadcrumbPath, folder]);
      setPage(1);
      setHasMore(true);
    } else {
      //alert(`Opening file: ${folder.name}`);
      setToastText(`Opening file: ${folder.name}`);
      setToastIndicator("info");
      setToastVisibility(true);
    }
  };

  const loadSortedData = (newPage: number) => {
    const selectedFolder = breadcrumbPath[breadcrumbPath.length - 1];

    // Filter out deleted items
    const filteredChildren =
      selectedFolder.children?.filter((child) => child.deleted !== 1) || [];

    // Sort the filtered children based on the sortConfig
    const sortedChildren = sortData(selectedFolder.children || []);

    // Paginate the sorted data
    const startIndex = (newPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const nextItems = sortedChildren.slice(startIndex, endIndex);

    if (newPage === 1) {
      setVisibleItems(nextItems); // Reset visible items for new data
    } else {
      setVisibleItems((prevItems) => [...prevItems, ...nextItems]);
    }

    setPage(newPage);
    setHasMore(nextItems.length === PAGE_SIZE); // Check if more data is available
    setLoading(false);
  };

  const handleSort = (column: any) => {
    setSortConfig((prev) => ({
      column,
      order: prev.column === column && prev.order === "asc" ? "desc" : "asc",
    }));
    setPage(1); // Reset to first page after sorting
  };

  const sortedAndFilteredItems = useMemo(() => {
    let sortedItems = [...visibleItems];

    if (sortConfig.column) {
      sortedItems.sort((a, b) => {
        const column = sortConfig.column;
        const order = sortConfig.order === "asc" ? 1 : -1;
        if (column === "created" || column === "modified") {
          const dateA = new Date(a[column]);
          const dateB = new Date(b[column]);

          if (dateA < dateB) return -1 * order;
          if (dateA > dateB) return 1 * order;
          return 0;
        }
        if (a[column] < b[column]) return -1 * order;
        if (a[column] > b[column]) return 1 * order;
        return 0;

      });
    }

    return sortedItems.filter((item) => !item.deleted);
  }, [visibleItems, sortConfig]);

  const sortData = (data: any) => {
    if (!Array.isArray(data)) return [];
    if (!sortConfig.column) return data; // If no sorting column, return data as is

    return [...data].sort((a, b) => {
      const column = sortConfig.column;
      const order = sortConfig.order === "asc" ? 1 : -1;

      if (column === "created" || column === "modified") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);

        if (dateA < dateB) return -1 * order;
        if (dateA > dateB) return 1 * order;
        return 0;
      }

      // Default string or numeric sorting
      if (a[column] < b[column]) return -1 * order;
      if (a[column] > b[column]) return 1 * order;
      return 0;
    });
  };

  const loadMoreItems = () => {
    if (loading || !hasMore) return;

    setLoading(true);
    loadSortedData(page + 1); // Load next page of sorted data
  };

  const createNewFolder = async (folder: string) => {
    if (!folder.trim()) {
      //alert("Error: " + "Folder name cannot be empty.");
      setToastText(`Error: Folder name cannot be empty.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return;
    }
    const currentFolderId: number =
      breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

    if (!currentFolderId || isNaN(Number(currentFolderId))) {
      //alert("Error: Something went wrong. Unable to determine the parent folder.");
      setToastText(`Error: Something went wrong. Unable to determine the parent folder.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return;
    }
    const existingFolder = visibleItems.filter(item => item.type === "folder").find(
      (item) =>
        item.parentId === breadcrumbPath[breadcrumbPath.length - 1]?.id &&
        item.name === folder.trim() && folder.trim()
    );
    if (existingFolder) {
      //alert(`File "${doc.name}" already exists in the current folder.`);
      setToastText(`"${folder}" already exists in the current folder.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return; // Stop further execution since overwrite is not allowed
    }

    const newFolder: ProjectRecord = {
      "name": folder,
      "type": "folder",
      "deleted": 0,
      "listId": props.listObj.id,
      "parentId": currentFolderId
    };

    //const endpoint = ${'https://localhost:44346/'}api/ProjectRecords
    const endpoint = `${clientAPIURL}api/ProjectRecords`
    const accessToken = await getAccessToken();
    const response = await createRecord(endpoint, accessToken, newFolder)

    //const json_response = JSON.parse(response);
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData.id);

      if (props.listObj.id) {

        const createdFolder: ProjectRecord = {
          id: responseData.id,
          name: newFolder.name,
          type: newFolder.type,
          parentId: newFolder.parentId,
          listId: newFolder.listId,
          deleted: newFolder.deleted,
          created: formatDate(new Date().toString()),
          modified: formatDate(new Date().toString()),
        };

        setToastText(`Success: Folder ${folder} created.`);
        setToastIndicator("success");
        setToastVisibility(true);
        await fetchRecords(props.listObj.id, currentFolderId, breadcrumbPath.map(folder => folder.id));
        //console.log(currentFolder);
        //   const updatedCurrentFolder = { ...breadcrumbPath[breadcrumbPath.length - 1] };
        //   updatedCurrentFolder.children = [...(updatedCurrentFolder.children || []), createdFolder];
        // // Update breadcrumb path and visible items
        //   setBreadcrumbPath([...breadcrumbPath]); // Maintain breadcrumb path
        //   setVisibleItems(updatedCurrentFolder.children); 
        //   //await fetchRecords(props.listObj.id);
        //   const updatedBreadcrumbPath = [...breadcrumbPath];
        //   const selectedFolder = updatedBreadcrumbPath[breadcrumbPath.length - 1];
        //   selectedFolder.children = [...(selectedFolder.children || []), createdFolder];
      }
    }


    //setBreadcrumbPath(updatedBreadcrumbPath); // Update breadcrumb path
    
    setNewFolderName(""); // Clear input
    setShowFolderModal(false);
  };

  const openEditFolderModal = () => {
    if (selectedItems.length !== 1) {
      //alert("Please select a single folder to edit.");
      setToastText(`Please select a single folder to edit.`);
      setToastIndicator("warning");
      setToastVisibility(true);
      return;
    }
    const selectedItem = visibleItems.find(
      (item) => item.id === selectedItems[0]
    );
    if (!selectedItem) {
      //alert("Selected item not found.");
      setToastText(`Selected item not found.`);
      setToastIndicator("warning");
      setToastVisibility(true);
      return;
    }

    // Check if the selected item is a file
    if (selectedItem.type === "file") {
      const fileNameParts = selectedItem.name.split(".");
      if (fileNameParts.length > 1) {
        const extension = fileNameParts.pop(); // Extract the extension
        const nameWithoutExtension = fileNameParts.join("."); // Handle names with multiple dots
        setNewFolderName(nameWithoutExtension); // Prepopulate only the name
        setFileExtension(extension); // Store the extension in state
      } else {
        // If no extension, treat it as a folder-like name
        setNewFolderName(selectedItem.name);
        setFileExtension(""); // No extension
      }
    } else {
      // If it's a folder, directly set the name
      setNewFolderName(selectedItem.name);
      setFileExtension(""); // No extension
    }

    setIsEditing(true);
    setShowFolderModal(true);
  };

  const updateFolder = async (folder: string) => {
    if (!parseInt(selectedItems[0])) {
      //alert("Please ensure to select a valid item.");
      setToastText(`Please ensure to select a valid item.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return;
    }

    if (!folder.trim()) {
      //alert("Name cannot be empty.");
      setToastText(`Name cannot be empty.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return;
    }

    const existingFolder = visibleItems.filter(item => !item.deleted).find(
      (item) =>
        item.parentId === breadcrumbPath[breadcrumbPath.length - 1]?.id &&
        item.name === folder.trim() && folder.trim()
    );
    if (existingFolder) {
      //alert(`File "${doc.name}" already exists in the current folder.`);
      setToastText(`"${folder}" already exists in the current folder.`);
      setToastIndicator("fail");
      setToastVisibility(true);
      return; // Stop further execution since overwrite is not allowed
    }

    const currentFolderId: number =
      breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

    // Combine the name and extension if it's a file
    const updatedName =
      fileExtension && fileExtension.trim()
        ? `${folder.trim()}.${fileExtension.trim()}`
        : folder.trim();

    const updatedItem: ProjectRecord = {
      id: parseInt(selectedItems[0]), // Assuming selectedItems[0] contains the ID of the selected item
      name: updatedName, // Use the updated name
      type: visibleItems.find((item) => item.id === selectedItems[0]).type, // Keep the original type
      listId: currentListId, // List ID from props
      parentId: currentFolderId, // Current folder ID
      deleted: 0, // Retain the deleted flag
    };

    try {
      //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords/${selectedItems[0]}`;
      const endpoint = `${clientAPIURL}api/ProjectRecords/${selectedItems[0]}`;
      const accessToken = await getAccessToken(); // Function to fetch the access token
      const response = await updateRecord(endpoint, accessToken, updatedItem); // Make the API call

      if (response.ok) {
        // Update local state if the API call was successful
        if (currentListId) {
          setToastText(`Item updated successfully.`);
          setToastIndicator("success");
          setToastVisibility(true);
          await fetchRecords(currentListId, currentFolderId, breadcrumbPath.map(folder => folder.id));
        }
        setSelectedItems([]); // Clear selection
        setNewFolderName(""); // Reset input
        setFileExtension(""); // Reset extension
        setShowFolderModal(false); // Close modal
        //alert("Item updated successfully.");
        setToastText(`Item updated successfully.`);
        setToastIndicator("success");
        setToastVisibility(true);
      } else {
        console.error(`Failed to update item: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error updating item:", error);
      //alert("Error: Failed to update item. Please try again.");
      setToastText(`Error: Failed to update item. Please try again.`);
      setToastIndicator("fail");
      setToastVisibility(true);
    }
  };

  const handleFileUpload = async (doc: any, overwrite: boolean) => {
    try {

      const currentFolderId = breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

      if (!currentFolderId || isNaN(Number(currentFolderId))) {
        //alert("Error: Unable to determine the parent folder.");
        setToastText(`Error: Unable to determine the parent folder.`);
        setToastIndicator("fail");
        setToastVisibility(true);
        return;
      }
      //console.log("File:", doc);
      //console.log("Overwrite:", overwrite);
      const existingFile = visibleItems.find(
        (item) =>
          item.parentId === breadcrumbPath[breadcrumbPath.length - 1]?.id &&
          item.name === doc.name
      );
      if (existingFile && !overwrite) {
        //alert(`File "${doc.name}" already exists in the current folder.`);
        setToastText(`File "${doc.name}" already exists in the current folder.`);
        setToastIndicator("fail");
        setToastVisibility(true);
        return; // Stop further execution since overwrite is not allowed
      }
      // Step 3: Check for a file with the same name
      //const existingFile = existingFiles.find((file) => file.name === doc.name);
      const blobId = existingFile?.blobId; // Get blobId if file exists
      //const overwrite = !!blobId;

      var myHeaders = new Headers();
      const accessToken = await getAccessToken();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var formdata = new FormData();
      let xhr = new XMLHttpRequest();

      if (Platform.OS === "web") {
        // Web-specific file append
        formdata.append("file", doc.file, doc.name);
      } else if (Platform.OS === "ios") {
        // iOS-specific file append
        formdata.append("file", doc);
        myHeaders.append("Content-Type", "multipart/form-data");
      } else {
        // Android-specific file append
        formdata.append("file", {
          uri: doc.uri,
          type: doc.mimeType || "application/octet-stream",
          name: doc.name,
        } as any);
      }

      // Add additional properties
      formdata.append("name", doc.name); // File name
      formdata.append("type", "file"); // File type
      formdata.append("parentId", currentFolderId.toString()); // Parent folder ID (ensure it's string)
      formdata.append("listId", currentListId); // Project/List ID
      if (blobId) formdata.append("blobId", blobId);
      formdata.append("overwrite", overwrite.toString());

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords/upload`;
        const endpoint = `${clientAPIURL}api/ProjectRecords/upload`;
        xhr.open("POST", endpoint);
        xhr.setRequestHeader("Authorization", "Bearer " + accessToken);

        // Send the FormData, which includes file and properties
        xhr.send(formdata);

        xhr.onload = async function () {
          if (xhr.status !== 200) {
            console.log(`Error ${xhr.status}: ${xhr.statusText}`);

          } else {
            //console.log(`Done, got ${xhr.response}`);
            //alert("File uploaded successfully!");
            setToastText(`File uploaded successfully!`);
            setToastIndicator("success");
            setToastVisibility(true);
            const response = JSON.parse(xhr.response);
            const uploadedFile: ProjectRecord = {
              name: doc.name,
              type: "file",
              parentId: currentFolderId,
              listId: currentListId,
              blobId: response.blobId,
              deleted: 0,
              created: formatDate(new Date().toString()),
              modified: formatDate(new Date().toString()),
            };

            // const updatedCurrentFolder = { ...breadcrumbPath[breadcrumbPath.length - 1] };
            // updatedCurrentFolder.children = [...(updatedCurrentFolder.children || []), uploadedFile];
            // setBreadcrumbPath([...breadcrumbPath]); // Maintain breadcrumb
            // setVisibleItems(updatedCurrentFolder.children);
            //console.log(uploadedFile);
            setToastText(`Success: File ${uploadedFile.name} has been uploaded.`);
            setToastIndicator("success");
            setToastVisibility(true);
            if (props.listObj.id) {
              
              await fetchRecords(props.listObj.id, currentFolderId, breadcrumbPath.map(folder => folder.id));
            }

            // const updatedBreadcrumbPath = [...breadcrumbPath];
            // const selectedFolder = updatedBreadcrumbPath[breadcrumbPath.length - 1];
            // selectedFolder.children = [...(selectedFolder.children || []), uploadedFile];

            //setBreadcrumbPath(updatedBreadcrumbPath); // Update breadcrumb path
            setNewFolderName(""); // Clear input
            setFileExtension("");
            setShowFolderModal(false);
          }
        };

        xhr.onerror = function () {
          console.error("Request failed", xhr.status, xhr.statusText, xhr.responseText);
        };
      } catch (ex) {
        console.error("Error during upload:", ex);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      //alert("Failed to upload file. Please try again.");
      setToastText(`Failed to upload file. Please try again.`);
      setToastIndicator("fail");
      setToastVisibility(true);
    }
  };
  const formatDate = (dateString: string) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  const toggleSelection = (itemId: string) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId)); // Unselect item
    } else {
      setSelectedItems([...selectedItems, itemId]); // Select item
    }
  };

  function markFolderAndChildrenDeleted(items: any) {
    return items.map((item: FolderItem) => {
      if (item.type === "folder" && item.children) {
        return {
          ...item,
          deleted: true,
          children: markFolderAndChildrenDeleted(item.children), // Recursively mark children as deleted
        };
      }
      return { ...item, deleted: true }; // Mark file or folder without children as deleted
    });
  }

  function updateDeletedStatusInHierarchy(folder: any, itemId: any) {
    if (folder.children) {
      folder.children = folder.children.map((child: any) => {
        if (child.id === itemId) {
          // Mark the item as deleted
          return { ...child, deleted: true };
        }
        if (child.type === "folder" && child.children) {
          // Recursively update nested folders
          return {
            ...child,
            children: updateDeletedStatusInHierarchy(child, itemId).children,
          };
        }
        return child;
      });
    }
    return folder;
  }

  async function handleDeleteSelected() {
    try {

      if (!selectedItems.length) {
        //alert("No items selected for deletion.");
        setToastText(`No items selected for deletion.`);
        setToastIndicator("fail");
        setToastVisibility(true);
        return;
      }
      //const endpoint = `${clientAPIURL}api/ProjectRecords/upload`;
      const response = await fetch(`${clientAPIURL}api/ProjectRecords/bulkDelete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(selectedItems), // Send selectedItems as a JSON array
      });

      if (!response.ok) {
        console.error(`Failed to delete items: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result.message);

      const currentFolderId: number =
      breadcrumbPath[breadcrumbPath.length - 1]?.id || null;
      setSelectedItems([]);
      setToastVisibility(true);
      setToastIndicator("success");
      setToastText("Selected items are deleted!");
      await fetchRecords(currentListId, currentFolderId, breadcrumbPath.map(folder => folder.id));

      // const updatedVisibleItems = visibleItems.map((item) => {
      //   if (selectedItems.includes(item.id)) {
      //     if (item.type === "folder" && item.children) {
      //       return {
      //         ...item,
      //         deleted: true,
      //         children: markFolderAndChildrenDeleted(item.children), // Recursively delete children
      //       };
      //     }
      //     return { ...item, deleted: true }; // Mark file or standalone folder as deleted
      //   }
      //   return item; // Leave unchanged items
      // });

      // setVisibleItems(updatedVisibleItems);

      // // Update the entire folder hierarchy
      // let updatedCurrentFolder = { ...currentFolder };
      // selectedItems.forEach((itemId) => {
      //   updatedCurrentFolder = updateDeletedStatusInHierarchy(
      //     updatedCurrentFolder,
      //     itemId
      //   );
      // });
      // setCurrentFolder(updatedCurrentFolder);

      // // Update breadcrumbPath to reflect changes
      // const updatedBreadcrumbPath = breadcrumbPath.map((folder) =>
      //   folder.id === currentFolder.id
      //     ? updatedCurrentFolder // Update the current folder
      //     : folder
      // );
      // setBreadcrumbPath(updatedBreadcrumbPath);

      
    } catch (error) {
      console.error("Error updating deleted status:", error);
      setToastVisibility(true);
      setToastIndicator("fail");
      setToastText("An error occurred while marking items as deleted.");
    }
  }

  async function handleConfirmDelete() {
    handleDeleteSelected();
    setShowConfirmation(false);
  };

  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    setShowConfirmation(false);
  };

  const downloadFile = async (blobId: string, fileName: string) => {
    try {
      
      const endpoint = `${clientAPIURL}api/ProjectRecords/download/${blobId}`;
      //const endpoint = `https://localhost:44346/api/ProjectRecords/download/${blobId}`;
      const accessToken = await getAccessToken();

      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        console.error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the suggested file name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file. Please try again.");
    }
  };
  //console.log(visibleItems.filter((item) => !item.deleted))
  //const [page, setPage] = useState(0);

  // Total pages calculation

  //const totalPages = Math.ceil(visibleItems.filter(item => !item.deleted).length / PAGE_SIZE);
  const optionsPerPage: number[] = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState<number>(optionsPerPage[0]);
  // Get items for the current page
  const paginatedItems = visibleItems.filter(item => !item.deleted).slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
  return (
    <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
      <View>
        <View>
          <>
            {showConfirmation && (
              <View>
                <ConfirmBox
                  title={"Confirmation"}
                  message={"Are you sure you want to proceed to delete selected items?"}
                  yesCallback={handleConfirmDelete}
                  noCallback={handleCancelDelete}
                />
              </View>
            )}

            {/* Folder Modal */}
            {visibleItems && (
              <CreateFolderModal
                showFolderModal={showFolderModal}
                setShowFolderModal={setShowFolderModal}
                newFolderName={newFolderName}
                setNewFolderName={setNewFolderName}
                createNewFolder={createNewFolder}
                updateFolder={updateFolder}
                isEditing={isEditing}
                fileExtension={fileExtension}
              />
            )}

            {/* Ribbon Buttons */}
            <ScrollView horizontal style={styles.ribbonScrollView}>
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  onPress={() => setShowFolderModal(true)}
                  style={[styles.button, props.disabled && styles.disabledButton]}
                  disabled={false}
                >
                  <Text style={styles.buttonText}>New Folder</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.buttonDivider}></View>

              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  onPress={handleUploadClick}
                  style={[styles.button, props.disabled && styles.disabledButton]}
                  disabled={false}
                >
                  <Text style={styles.buttonText}>Upload</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.buttonDivider}></View>
              {props.logTitle !== "Report Request" && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    onPress={openEditFolderModal}
                    style={
                      selectedItems.length !== 1 ? styles.disabledButton : styles.button
                    }
                    disabled={selectedItems.length !== 1}
                  >
                    <Text style={styles.buttonText}>Rename</Text>
                  </TouchableOpacity>
                </View>
              )}

              <View style={styles.buttonDivider}></View>

              {props.logTitle !== "Report Request" && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowConfirmation(true);
                    }}
                    style={
                      selectedItems.length === 0
                        ? styles.disabledButton
                        : styles.button
                    }
                    disabled={selectedItems.length === 0}
                  >
                    <Text style={styles.buttonText}>Delete</Text>
                  </TouchableOpacity>
                </View>
              )}
            </ScrollView>

            {/* Breadcrumb Navigation */}
            <View style={styles.breadcrumbContainer}>
              {breadcrumbPath.map((folder: any, index: number) => (
                <React.Fragment key={`breadcrumb-${folder.id || index}`}>
                  <TouchableOpacity
                    onPress={() => navigateToBreadcrumb(index)}
                    style={styles.breadcrumbItem}
                    key={`breadcrumb-item-${folder.id || index}`} // Ensure unique key here
                  >
                    <Text style={styles.breadcrumbText}>{folder.name}</Text>
                  </TouchableOpacity>
                  {index < breadcrumbPath.length - 1 && (
                    <Text
                      style={styles.breadcrumbSeparator}
                      key={`breadcrumb-separator-${index}`} // Ensure unique key here
                    >
                      →
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </View>
            <ToastMessageAnnimated
              message={toastText}
              visible={toastVisibility}
              onDismiss={() => setToastVisibility(false)}
              indicator={toastIndicator?toastIndicator:"info"}
            />
            {/* Upload Modal */}
            <ProjectRecordUploadModal
              visible={isModalVisible}
              onClose={handleModalClose}
              onSubmit={handleFileUpload}
            />
          </>
        </View>

        <View style={styles.container}>
          <DataTable>
            <ScrollView horizontal contentContainerStyle={{ flexDirection: 'column', flexGrow: 1 }}>
              {/* Header Row */}
              <DataTable.Header >
                <DataTable.Title style={{ flex: 1, width: 75 }}><Text style={{ fontWeight: "900", fontSize: 14 }}>Select</Text></DataTable.Title>
                <DataTable.Title onPress={() => handleSort("name")} style={styles.cell}><Text style={{ fontWeight: "900", fontSize: 14 }}>Name</Text></DataTable.Title>
                <DataTable.Title onPress={() => handleSort("created")} style={styles.cell}><Text style={{ fontWeight: "900", fontSize: 14 }}>Created</Text></DataTable.Title>
                <DataTable.Title onPress={() => handleSort("modified")} style={styles.cell}><Text style={{ fontWeight: "900", fontSize: 14 }}>Modified</Text></DataTable.Title>
              </DataTable.Header>

              {/* Data Rows */}
              {sortedAndFilteredItems
                .filter(item => !item.deleted)
                .map((item, index) => (
                  <DataTable.Row key={index} style={styles.row}>
                    {/* Checkbox for selection */}
                    <DataTable.Cell style={{ flex: 1, width: 75 }}>
                      <Checkbox.Android
                        status={selectedItems.includes(item.id) ? "checked" : "unchecked"}
                        onPress={() => toggleSelection(item.id)}
                        uncheckedColor="#666"
                        color="#007BFF"
                      />
                    </DataTable.Cell>

                    {/* File/Folder Name with Pressable */}
                    <DataTable.Cell style={styles.cell}>
                      <TouchableOpacity
                        onPress={() => {
                          if (item.type === "folder") {
                            openFolder(item);
                          } else {
                            toggleSelection(item.id);
                          }
                        }}
                      >
                        {item.type === "folder" ? (
                          <Text style={styles.cellText}>📁 {item.name}</Text>
                        ) : (
                          <Text
                            style={[styles.cellText, item.type === "file" && styles.hyperlink]}
                            onPress={() => downloadFile(item.blobId, item.name)}
                          >
                            📄 {item.name}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </DataTable.Cell>

                    {/* Date Created */}
                    <DataTable.Cell style={styles.cell}>
                      <Text style={styles.cellText}>{item.created}</Text>
                    </DataTable.Cell>

                    {/* Date Modified */}
                    <DataTable.Cell style={styles.cell}>
                      <Text style={styles.cellText}>{item.modified}</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}

            </ScrollView>
            {/* Pagination Component */}
            {/* <DataTable.Pagination
          numberOfItemsPerPageList={optionsPerPage}
          numberOfItemsPerPage={itemsPerPage}
          page={page}
          numberOfPages={Math.ceil(visibleItems.filter(item => !item.deleted).length / itemsPerPage)}
          onItemsPerPageChange={setItemsPerPage}
          onPageChange={(newPage) => setPage(newPage)}
          label={`${page * itemsPerPage + 1} to ${Math.min( (page + 1) * itemsPerPage, visibleItems.filter(item => !item.deleted).length )} of ${visibleItems.filter(item => !item.deleted).length}`}
          selectPageDropdownLabel={'Rows per page'}
          showFastPaginationControls
        /> */}
          </DataTable>
          <View>
            {hasMore ? (
              <TouchableOpacity
                style={styles.loadMoreButton}
                onPress={loadMoreItems}
              >
                {loading ? (
                  <ActivityIndicator size="small" color="#fff" />
                ) : (
                  <Text style={styles.loadMoreText}>Load More</Text>
                )}
              </TouchableOpacity>
            ) : (
              <Text style={styles.noMoreText}>No more items to load</Text>
            )}
          </View>
        </View>
      </View>
    </ScrollView>

  );

}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    //borderWidth: 1, 
    borderColor: 'grey',
    paddingTop: 10
  },
  headerCellCheckbox: {
    width: 40,
  },
  headerCellName: {
    flex: 2,
    width: 200,
    //align:'flex-start'
  },
  headerCell: {
    flex: 1,
  },
  row: {
    paddingVertical: 10,
  },
  cellCheckbox: {
    width: 40,
    //justifyContent: 'center',
  },
  cellNameContainer: {
    flex: 2,
    width: 200
    //justifyContent: 'center',
  },
  cellName: {
    fontSize: 16,
  },
  hyperlink: {
    color: '#007BFF',
    textDecorationLine: 'underline',
  },
  cell: {
    flex: 1,
    width: 250
    //justifyContent: 'center',
  },




  breadcrumbContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  breadcrumbItem: {
    paddingHorizontal: 5,
  },
  breadcrumbText: {
    fontSize: 14,
    color: "#007BFF",
  },
  breadcrumbSeparator: {
    fontSize: 14,
    color: "#000",
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: "#f1f1f1",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },


  cellText: {
    fontSize: 16,
    color: "#333"
  },

  headerText: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#000"
  },
  loadMoreButton: {
    padding: 15,
    backgroundColor: "#007BFF",
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  loadMoreText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  noMoreText: {
    textAlign: "center",
    marginVertical: 10,
    fontSize: 14,
    color: "#555",
  },
  ribbonScrollView: {
    flexDirection: "row",
    backgroundColor: "#f4f4f4",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
  },
  button: {
    backgroundColor: "#0078D4", // Default button color
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    minWidth: 100,
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "gray", //"#676767", // Disabled button color
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    minWidth: 100,
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "600",
    fontSize: 14,
  },
  buttonDivider: {
    width: 1,
    backgroundColor: "#ddd",
    marginHorizontal: 5,
  },

})