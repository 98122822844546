import React, { useState, useMemo } from 'react';
import { View, StyleSheet, Text, Dimensions, ScrollView } from 'react-native';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, VictoryVoronoiContainer } from 'victory-native';
interface BarChartScreenProps {
  label: string[];
  data: any;
  ChartTitle: string;
  subTitle?: string;
  legend?: any;
  Type:string;
  threshold?:number
}
function SingleBarChartComp(props: BarChartScreenProps) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const screenWidth = Dimensions.get('window').width;
  // const chartWidth = props.data.labels.length > 2 ? screenWidth : screenWidth / 2;
  const chartWidth = Math.max(screenWidth/1.2, props.data.labels.length * 50); // Dynamic width with minimum limit
  // Memoize transformations
  const transformedDatasets = useMemo(() => {
    return props.data.datasets.map((dataset: any) => ({
      data: dataset.data.slice(0, 5).map((value: any, dataIndex: number) => ({
        x: props.data.labels[dataIndex],
        y: value,
      })),
    }));
  }, [props.data]);

  const transformedDatasetsAll = useMemo(() => {
    return props.data.datasets.map((dataset: any) => ({
      data: dataset.data.map((value: any, dataIndex: number) => ({
        //x: props.data.labels[dataIndex],
        //y: parseFloat(value.toFixed(2)),
        x: props.Type === 'Single' ? props.data.labels[dataIndex] : value.x, // Use the `x` value as-is (station name)
        y: props.Type === 'Single' ? parseFloat(value.toFixed(2)) : parseFloat(value.y.toFixed(2)),
      })),
    }));
  }, [props.data]);

  
  return (
    <View style={[styles.container]}>
      {props.data.datasets.length === 0 ? (
        <Text style={styles.noDataText}>{`No data available for ${props.ChartTitle}`}</Text>
      ) : (
        <ScrollView horizontal>
          <VictoryChart
            width={chartWidth}
            domainPadding={
              props.data.labels.length > 10
                ? { x: 10 }
                : props.data.labels.length > 2
                ? { x: 50 }
                : { x: 100 }
            } // Dynamic padding
            containerComponent={
              <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}: ${datum.y.toFixed(2)}`} />
            }
          >
            {/* X-Axis */}
            <VictoryAxis
              tickFormat={(t) => (t.length > 10 ? `${t.slice(0, 10)}...` : t)}
              style={{
                tickLabels: {
                  fontSize: 10,
                  angle: props.data.labels.length > 5 ? 60 : 45,
                  textAnchor: 'start', // Align text
                },
              }}
            />
            {/* Y-Axis */}
            <VictoryAxis dependentAxis />
            {/* Threshold Line */}
            {props.threshold && (
              <VictoryLine
                style={{
                  data: { stroke: 'red', strokeDasharray: '5,5' }, // Dashed red line
                }}
                data={[
                  { x: 0, y: props.threshold }, // Start of line
                  { x: props.data.labels.length, y: props.threshold }, // End of line
                ]}
              />
            )}
            <VictoryGroup offset={10} colorScale="qualitative">
              {transformedDatasetsAll.map((dataset: any, index: any) => (
                <VictoryBar
                  key={index}
                  data={dataset.data}
                  barRatio={props.data.labels.length > 2 ? 0.8 : 0.5} // Reduce bar width for fewer bars
                  barWidth={({ index }) => {
                    const maxBarWidth = props.Type === 'Single'? 10 : 5; // Maximum bar width
                    const calculatedWidth = chartWidth / props.data.labels.length / 2; // Scale bar width
                    return Math.min(maxBarWidth, calculatedWidth); // Cap the bar width
                  }}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 1000 },
                  }}
                  style={{
                    data: {
                      stroke: props.data.datasets[index].color(1),
                      fill: props.data.datasets[index].color(1),
                    },
                  }}
                />
              ))}
            </VictoryGroup>
          </VictoryChart>
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  //container: { padding: 16, justifyContent:'center', alignContent:'center', alignSelf:'center' },
  container: { padding: 16 },
  noDataText: { fontSize: 16, textAlign: 'center', color: '#999' },
});

export default SingleBarChartComp;
