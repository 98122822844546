import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { DataTable } from 'react-native-paper';
import ControlStyles from './ControlStyles';
import ButtonControl from './Button';
import { handleExportClick } from '../../ConsolidatedLogs/ConsolidatedController';
interface Props {
  data: Array<any>;
  columns: Array<any>;
  siteId: string;
  logTitle: string;
  tableTitle?: string;
}

const CustomDT: React.FC<Props> = (props) => {
  const optionsPerPage: number[] = [10, 20, 50];
  const data = props.data;
  const columns = props.columns;
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(optionsPerPage[1]);

  if (!data || data.length === 0) {
    return (
      <View style={[ControlStyles.dtHeader, { height: 40 }]}>

        <Text style={[ControlStyles.dtTitle]}>{props.tableTitle && props.tableTitle} : Oops! No records found</Text>
      </View>
    );
  }

  return (
    <View>
      <View style={ControlStyles.tableContainer}>
        <View style={[ControlStyles.dtHeader, { height: 40, width: 'auto' }]}>
          <View><Text style={[ControlStyles.dtTitle]}>{props.tableTitle && props.tableTitle}</Text></View>
          <View style={ControlStyles.buttonDivider}></View>
              <View>
                <View style={ControlStyles.buttonContainer}>
                  <ButtonControl icon="export-variant" mode="contained" onPress={() => handleExportClick(data, props.tableTitle && `${props.tableTitle}`)} Title='Export' disabled={data.length > 0 ? false : true} />
                </View>
              </View>
        </View>
        <DataTable>
          <ScrollView horizontal contentContainerStyle={{ flexDirection: 'column' }}>
            <DataTable.Header style={ControlStyles.dtHeader}>
              {columns.map((column, index) => (
                <DataTable.Title key={index} numberOfLines={1} style={[ControlStyles.dtTitle, { width: column.Title ? column.Title.length * 10 : column.ColumnName.length * 10 }]}>
                  <Text style={ControlStyles.dtHeaderText}>{column.Title ? column.Title : column.ColumnName}</Text></DataTable.Title>
              ))}
            </DataTable.Header>
            {data.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((row, index) => (
              <DataTable.Row key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : 'lightgray' }}>
                {columns.map((column, index) => (
                  <DataTable.Cell key={index} style={[ControlStyles.dtCell, { width: column.Title ? column.Title.length * 10 : column.ColumnName.length * 10 }]}>{row[column.ColumnName]}</DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </ScrollView>
          <DataTable.Pagination
            numberOfItemsPerPageList={optionsPerPage}
            numberOfItemsPerPage={itemsPerPage}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(page) => setPage(page)}
            onItemsPerPageChange={setItemsPerPage}
            showFastPaginationControls
            selectPageDropdownLabel={'Rows per page'}
            label={`${page * itemsPerPage + 1}-${Math.min(
              (page + 1) * itemsPerPage,
              data.length
            )} of ${data.length}`}
            style={{ alignSelf: 'flex-start' }}
          />
        </DataTable>
      </View>
    </View>
  );

}

export default CustomDT;