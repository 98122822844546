import { format, toZonedTime } from 'date-fns-tz';

async function getAssetData(imageFileName: string, accessToken: string, clientAPIURL: string) {
  try {
    const finalUri = clientAPIURL + "api/getAsset/" + imageFileName;
    const response = await fetch(finalUri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'cache':'force-cache'
      },
    });
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

async function triggerWorkflow(path: string, itemID: number, status: string, packageName: string, siteId: string, listTitle: string, api: string, accessToken: string) {
  const data = {
    "listPath": path,//"greenport/dgda/ss01/TrainingObservations/EnvironmentalIssuesObservations",
    "itemID": itemID,
    "status": status,
    "packageName": packageName,
    "siteId": siteId,
    "listTitle": listTitle
  }
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(api + "api/triggerWorkflow", requestOptions);
    return result;
  } catch (error) {
    console.error(error);
  }

}

async function triggerReportRequest(
  logTitle: string,
  accessToken: string,
  clientAPIURL: string,
  data:any
) {
  // const data = {
  //   template: template,
  //   period: period,
  //   logTitle: logTitle
  // };

  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${clientAPIURL}api/triggerReportRequest`, requestOptions);

    if (!response.ok) {
      // Handle HTTP errors
      console.error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = response
    return result; // Return the parsed JSON response
  } catch (error) {
    console.error('Error in triggerReportRequest:', error);
    console.error(error) // Re-throw error for caller to handle if needed
  }
}


async function getAllItems(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string) {

  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  try {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const response = await fetch(finalUri, requestOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
async function createItem(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string, data: Array<any>) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(finalUri, requestOptions);
    const response = await result.json();
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function upload(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string, data: any) {

  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(finalUri, requestOptions);
    const response = await result.json();
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getItemById(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string, itemId: number) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const url = finalUri + "/" + itemId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function getAppConfig(accessToken: string, clientAPIURL: string, key:any) {
  const finalUri = clientAPIURL + "api/AppConfigs"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const url = finalUri + "/wasteconversionfactors"
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
const formatDateToLocal = (dateString: string) => {
  if (!dateString) return "";
  const utcDate = new Date(`${dateString}Z`);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = toZonedTime(utcDate, timeZone);
  //console.log(`${timeZone} = ${dateString} = ${zonedDate}`)

  // Format the date as DD/MM/YYYY hh:mm (24-hour format)
  return format(zonedDate, 'dd/MM/yyyy HH:mm');
};
async function updateItem(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string, itemId: number, data: Array<any>) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  const url = finalUri + "/" + itemId
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(url, requestOptions);
    return result;
  } catch (error) {
    console.error(error);
  }
}
async function deleteItem(logTitle: string, accessToken: string, clientAPIURL: string, siteId: string, itemId: number) {
  
  //clientAPIURL = "https://localhost:44346/";
  const finalUri = `${clientAPIURL}api/sites/${siteId}/lists/${logTitle}/items/${itemId}`;
  
  try {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await fetch(finalUri, requestOptions);

    if (!response.ok) {
      throw new Error(`Delete request failed: ${response.status} ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error("Error deleting item:", error);
    return null; // Ensure failure is handled gracefully
  }
}

export {formatDateToLocal, getAllItems, createItem, deleteItem, updateItem, getItemById, upload, triggerWorkflow, triggerReportRequest, getAssetData, getAppConfig };
