import React from 'react';
import * as XLSX from 'xlsx';
import * as Permissions from 'expo-permissions';
import * as FileSystem from 'expo-file-system';
import { Platform, StyleSheet } from 'react-native';
import { useClient } from '../../../providers/ClientProvider';
import { useAuth } from '../../../providers/AuthProvider';
const FilterChoices = (uniqueItemsArray: any, key: string) => {

  const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const uniqueChoices =
    key === "Month" ?
      uniqueItemsArray && uniqueItemsArray.filter((item: any) => item !== null).sort((a: any, b: any) => monthOrder.indexOf(a) - monthOrder.indexOf(b))
      :
      uniqueItemsArray && uniqueItemsArray.filter((item: any) => item !== null).sort((a: any, b: any) => b - a);
  const FilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueChoices.map((item: any) => {
      return {
        label: item,
        value: item,
      };
    }))]

  return FilterOptions;
}
const GetConsolidatedLog = async (logTitle: string, clientAPIURL: string, accessToken: string, projectId?:string) => {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  //const finalUri = clientAPIURL + "api/consolidated/" + logTitle;
  //clientAPIURL = "https://localhost:44346/";
  const finalUri = projectId 
    ? `${clientAPIURL}api/consolidated/${logTitle}?projectId=${encodeURIComponent(projectId)}` 
    : `${clientAPIURL}api/consolidated/${logTitle}`;
  const json: any = await fetch(finalUri, requestOptions);
  const jResponse = await json.json();
  return jResponse;
}
const handleExportClick = async (data: any[]) => {
  try {
    if (Platform.OS === "web") {
      WebExportToExcel(data);
    }
    else {
      if (Platform.OS === 'ios') {
        const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
        else {
          MobileExportDataToExcel(data)
        }
      } else if (Platform.OS === 'android') {
        const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
        if (status !== 'granted') {
          alert('Sorry, we need write storage permissions to make this work!');
        }
        else {
          MobileExportDataToExcel(data)
        }
      }
    }
  } catch (e) {
    console.log('Error while checking permission');
    console.log(e);
    return
  }
};
const MobileExportDataToExcel = (data: any[]) => {
  let wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  let binaryStr = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
  let base64 = XLSX.write(wb, { type: "base64", bookType: "xlsx" });
  let bytes: any = new Uint8Array(binaryStr.length);
  for (let i = 0; i < binaryStr.length; i++) {
    bytes[i] = binaryStr.charCodeAt(i);
  }
  let dir = FileSystem.documentDirectory;
  let base64Data = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
  FileSystem.writeAsStringAsync(
    `${dir}data.xlsx`,
    base64Data
  )
    .then((uri) => {
      console.log('Finished writing to ', uri);
    })
    .catch(e => {
      console.error(e);
    });
}
function WebExportToExcel(data: any[]) {
  //const data = newData
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "output.xlsx");
}

function handleFilterReset(setSelectedMonth: any, setSelectedYear: any, setSelectedPackage: any, setNewData: any, updatedData: any) {
  
  setSelectedMonth('ALL');
  setSelectedYear('ALL');
  setSelectedPackage('ALL');
  setNewData(updatedData);
}
function filterData(sYear: string, sMonth: string, sPackage: string, setToastVisibility: any, updatedData: any[], setToastText: any, setNewData: any) {
  let filteredRows = updatedData;
  if (sYear !== 'ALL') {
    filteredRows = filteredRows.filter((item: any) => item.Year === sYear);
  }
  if (sMonth !== 'ALL') {
    filteredRows = filteredRows.filter((item: any) => item.Month === sMonth);
  }
  if (sPackage !== 'ALL') {
    filteredRows = filteredRows.filter((item: any) => item.packageName === sPackage);
  }
  if (filteredRows.length === 0) {
    setToastVisibility(true);
    setToastText("No items to show for the applied filter.")
  }
  else {
    setNewData(filteredRows);
  }
}
function constructData(response: any) {
  for (let i = 0; i < response.length; i++) {
    // add "packageName" to the columns set
    if (response[i].response != null) {
      response[i].response.columns.push({
        "id": "packageName",
        "title": "Package Name",
        "columnName": 'packageName',
        "datatype": "strings",
        "hidden": true
        // add any other properties you need for this column
      });

      // add "packageName" to each item in the items set
      for (let j = 0; j < response[i].response.items.length; j++) {
        response[i].response.items[j]["packageName"] = response[i].packageName;
      }
    }
  }
  let allColumns = response[0] && response[0].response && response[0].response.columns && response[0].response.columns;

  let allItems = response.reduce((acc: any, curr: any) => {
    return curr.response?.items ? acc.concat(curr.response.items) : acc;
}, []);

  let flattenedData = {
    columns: allColumns && allColumns,
    items: allItems && allItems
  };
  return flattenedData
}
function EnableExport() {
  return Platform.OS === "web" ? true : false;
}

const CommonStyles = StyleSheet.create({
  filterDropdown: {
    minWidth: 100,
  },
  flexDirectionRow:{
    flexDirection:'row',
  },
  flexDirectionCol:{
    flexDirection:'column',
  },
  ResetExportButtons:{
    padding:5
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 16,
  },
  ribbonIconContainer:{
    flexDirection: 'row',
  },
  IconContainer:{
    
  },
  container: {
    // flex: 1,
    // //padding: 16,
    // gap: 20,
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // alignItems: 'center',
    // justifyContent: 'space-evenly'

    flex: 1,
    gap: 20,
    flexGrow:1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  card: {
    // maxWidth: 370,
    // minWidth: 300,
    // marginVertical: 8,
    // minHeight: 100,
    // width: '100%',
    // //height:'100%'
    maxWidth: 500,
    minWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    width: '100%',
    //height:'100%'
  },
  cardTwo: {
    maxWidth: Platform.OS === 'web' ? '70%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardThree: {
    maxWidth: Platform.OS === 'web' ? '90%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardTwoFilter:{
    maxWidth: Platform.OS === 'web' ? '70%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardThreeFilter:{
    maxWidth: Platform.OS === 'web' ? '90%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  filterCard: {
    maxWidth: 250,
    minWidth: 150,
    marginVertical: 8,
    minHeight: 100,
    width: '100%',
  },
  divider: {
    // borderBottomWidth: 1,
    // borderBottomColor: 'silver',
    // marginBottom: 20,
  }
});
export { filterData, handleFilterReset, handleExportClick, constructData, EnableExport, CommonStyles, GetConsolidatedLog, FilterChoices }