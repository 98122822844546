import React, { useState, useEffect } from 'react';
import { ScrollView, View, StyleSheet, Text, TouchableOpacity, Platform, Linking } from 'react-native';
import { DataTable, Portal, Provider, Checkbox, Button, Tooltip, IconButton, MD3Colors } from 'react-native-paper';
import ButtonControl from './Button';
import ToastMessage from './Toast';
import { deleteItem } from '../service/dataOperations';
import PaperDialog from './PaperDialog';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ControlStyles from './ControlStyles';
import ConfirmBox from '../../../Common/ConfirmBox';
import { useNav } from '../../../../providers/NavProvider';
import { handleExportClick } from '../../ConsolidatedLogs/ConsolidatedController';

interface Props {
  data: Array<any>;
  columns: Array<any>;
  updateFormState: () => void;
  updateFormMode: (val: string) => void;
  handleRowPress: (val: Number[]) => void;
  ViewPressAction: (val: any) => void;
  EditPressAction: (val: any) => void;
  NewPressAction: () => void;
  siteId: string;
  logTitle: string;
  isConsolidatedTable: boolean;
  setRefreshData?: (e: boolean) => void;
  selectedYear?: string;
}

const DT: React.FC<Props> = (props) => {

  const optionsPerPage: number[] = [10, 20, 50];
  const [data, setData] = useState<Array<any>>([]);
  const [permissions, setpermissions] = useState("read");
  const [siteTitle, setSiteTitle] = useState("")
  const { sitesResp, setCurrentLeafObj, currentLeafObj } = useNav();
  function findParentSite(siteresp: any, siteId: string, parent: any = null): any | null {
    if (!siteresp?.children?.length) {
      return null; // No children, return null
    }
  
    for (const child of siteresp.children) {
      if (child.id === siteId) {
        return parent; // Return the direct parent
      }
  
      // Recursively search in child nodes
      const foundParent = findParentSite(child, siteId, child);
      if (foundParent) return foundParent;
    }
  
    return null; // Parent not found
  }
  
  function findSiteById(siteresp: any, siteId: string) {
    if (!siteresp.children || siteresp.children.length === 0) {
      return null; // No children, return null
    }

    for (let child of siteresp.children) {
      if (child.id === siteId) {
        return child; // Found the matching site
      }

      // Recursively search in child nodes
      const foundSite: any = findSiteById(child, siteId);
      if (foundSite) return foundSite;
    }

    return null; // Site not found in any children
  }
  React.useEffect(() => {

    if (sitesResp) {
      const parentSite = findParentSite(sitesResp, props.siteId);
      const matchedSite = findSiteById(sitesResp, props.siteId);
      parentSite && parentSite.title && setSiteTitle(parentSite.title);
      //console.info(matchedSite)
      matchedSite && matchedSite.permissions && setpermissions(JSON.stringify(matchedSite.permissions && matchedSite.permissions).toLowerCase())
    }
  }, [sitesResp]);
  const [canWriteData, setCanWriteData] = useState(false);
  useEffect(() => {
    setCanWriteData(
      permissions.includes('admin') ||
      permissions.includes('manage') ||
      permissions.includes('write') ||
      permissions.includes('contribute')
    );
  }, [permissions]);
  useEffect(() => {
    if (props.logTitle) {//props.logTitle === "Environmental Issues Observations"
      const dataWithLinksAndFiles = props.data && props.data.sort((a, b) => b.ID - a.ID).map(item => {
        // Parse the Attachments column
        let attachments = item.Attachments && JSON.parse(item.Attachments || "[]");

        // Only parse Location if it contains the string "coords"
        let locationLink = item.Location && item.Location
        if (item.Location && item.Location.includes("coords")) {
          let location = JSON.parse(item.Location || "{}");
          if (location?.coords) {
            const { latitude, longitude } = location.coords;
            locationLink = `https://maps.google.com/?q=${latitude},${longitude}`;
          }
        }
        return {
          ...item,
          // Attachments: attachments.length > 0
          //   ? attachments.map((att: any) => att.fileName).join(', ')
          //   : '',
          Attachments: attachments && attachments.length > 0
            ? attachments.length  // Return the count of files
            : 0,

          // Form link only if the "coords" substring exists and coordinates are parsed
          Location: locationLink
            ? `${locationLink}`
            : '' // Show empty string if no location or no coords
        };
      });

      //dataWithLinksAndFiles && setData(dataWithLinksAndFiles.sort((a, b) => b.ID - a.ID));
      //data = dataWithLinksAndFiles.sort((a, b) => b.ID - a.ID)

      if (dataWithLinksAndFiles) {
        // Ensure sorting follows the preferred column order
        const sortedData = dataWithLinksAndFiles.map(item => {
          const preferredOrder = ["packageName", "Contractor", "Date", "Month", "Year"];
          const sortedItem: any = {};
  
          // Add preferred columns first (if they exist)
          preferredOrder.forEach(col => {
            if (item.hasOwnProperty(col)) {
              sortedItem[col] = item[col];
            }
          });
  
          // Then add remaining columns in alphabetical order
          Object.keys(item)
            .filter(key => !preferredOrder.includes(key))
            .sort()
            .forEach(key => {
              sortedItem[key] = item[key];
            });
  
          return sortedItem;
        });
        setData(sortedData);
      }
    }
    else props.data && setData(props.data.sort((a, b) => b.ID - a.ID));
  }, [props.data])
  const [columns, setColumns] = useState<Array<any>>([]);



  // useEffect(() => {
  //   setColumns([...props.columns].sort((a, b) => a.order - b.order));
  // }, [props.columns]);
  useEffect(() => {
    if (props.isConsolidatedTable && props.isConsolidatedTable === true) {
      const preferredOrder = ["packageName", "Contractor", "Status", "Date", "Month", "Year"];
      
      const sortedColumns = [...props.columns].sort((a, b) => {
        // Get the index of both columns in preferredOrder array
        const indexA = preferredOrder.indexOf(a.columnName);
        const indexB = preferredOrder.indexOf(b.columnName);
  
        // If both columns are in the preferredOrder, sort them based on that order
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
  
        // If only one column is in preferredOrder, it should come first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
  
        // Otherwise, sort by 'order' property
        return a.order - b.order;
      });
  
      setColumns(sortedColumns);
    }
    else{
      const preferredOrder = ["ID", "Status", "ClosedOut"];
      
      const sortedColumns = [...props.columns].sort((a, b) => {
        // Get the index of both columns in preferredOrder array
        const indexA = preferredOrder.indexOf(a.columnName);
        const indexB = preferredOrder.indexOf(b.columnName);
  
        // If both columns are in the preferredOrder, sort them based on that order
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
  
        // If only one column is in preferredOrder, it should come first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
  
        // Otherwise, sort by 'order' property
        return a.order - b.order;
      });
  
      setColumns(sortedColumns);
      //setColumns([...props.columns].sort((a, b) => a.order - b.order));
    }
  }, [props.columns]);
  
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(optionsPerPage[2]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  //console.info(columns);
  //console.info(data);
  function handleRowClick(row: any) {
    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
    props.handleRowPress(row);
  };
  function handleCheckboxPress(row: any) {

    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
    props.handleRowPress(row);
  };
  function handleNew() {
    props.updateFormMode("New");
    //props.NewPressAction();
  };
  function handleEditPress(sRows: any) {
    if (sRows.length === 0) {
      setToastVisibility(true);
      setToastText("Please select atleast one item to view.")

    }
    else {
      props.updateFormMode("Edit");
      props.ViewPressAction(sRows);
    }
  };
  function handleViewPress(sRows: any) {
    if (sRows.length === 0) {
      setToastVisibility(true);
      setToastText("Please select atleast one item to view.")
    }
    else {
      props.updateFormMode("View");
      props.ViewPressAction(sRows);

    }
  };
  async function handleDeletePress(sRows: any) {
    setShowConfirmation(true);
  };
  async function handleConfirmDelete() {
    try {

      const accessToken = await getAccessToken();

      // Send delete requests in parallel for better performance
      const deleteRequests = selectedRows.map((item) =>
        deleteItem(props.logTitle, accessToken, clientAPIURL, props.siteId, item)
      );

      const results = await Promise.all(deleteRequests);

      let allSuccess = true;

      for (const result of results) {
        if (!result) {
          allSuccess = false;
          continue;
        }
        if (result.status === 200) {
          setToastText("Deleted successfully.");

          setData((prevData) => {
            const updatedData = prevData.filter((item) => !selectedRows.includes(item.ID));
            console.log("Updated Data:", updatedData); // Check if item 34 is removed
            return updatedData;
          });
          // const updatedData = data.filter((item) => !selectedRows.includes(item.ID))
          // data = updatedData;
          props.setRefreshData(true);
        } else if (result.status === 401 || result.statusText === "Unauthorized") {
          setToastText("Delete Failed: You are not authorized to perform this action.");
        } else {
          setToastText("Delete failed: Unexpected error.");
          allSuccess = false;
        }
      }

      setToastVisibility(true);

      if (allSuccess) {
        //props.setRefreshData(true);

      }

      setShowConfirmation(false);
    } catch (error) {
      console.error("Delete Error:", error);
      setToastText("Delete failed: Network or server issue.");
      setToastVisibility(true);
      setShowConfirmation(false);
    }
  };
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    setShowConfirmation(false);
  };
  function handleYearSelect(val: string) {
    //console.log(val)
    //props.handleYearFilterSelect(val);
  };
  const GoToLocation = (locationLink: any) => {
    //debugger;
    console.log(locationLink);
    if (locationLink) {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        Linking.openURL(locationLink);
      }
      else if (Platform.OS === 'web') {
        const googleMapLink = locationLink && locationLink;
        googleMapLink && window.open(googleMapLink, '_blank');
      }
      //Linking.openURL(locationLink); // Open Google Maps with the coordinates
    }
  };
  return (
    <View>
      <View style={ControlStyles.ribbonContainer}>
        {props.isConsolidatedTable === true ?

          <ScrollView horizontal style={ControlStyles.ribbonScrollView}>
            <View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="eye-outline" mode="contained" onPress={() => handleViewPress(selectedRows)} Title='View' disabled={selectedRows.length === 1 ? false : true} />
            </View>
            {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
            {props.logTitle === 'Report Request' ? null :
              <View>
                <View style={ControlStyles.buttonContainer}>
                  <ButtonControl icon="export-variant" mode="contained" onPress={() => handleExportClick(data, props.logTitle && `ConsolidatedData_${props.logTitle}`)} Title='Export' disabled={data.length > 0 ? false : true} />
                </View>
              </View>
            }
          </ScrollView>
          :
          <ScrollView horizontal style={ControlStyles.ribbonScrollView}>
            <View><View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="plus" mode="contained" onPress={() => handleNew()} Title='New' disabled={canWriteData === true ? false : true} />
            </View></View>
            <View style={ControlStyles.buttonDivider}></View>
            <View><View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="eye-outline" mode="contained" onPress={() => handleViewPress(selectedRows)} Title='View' disabled={selectedRows.length === 1 ? false : true} />
            </View></View>
            {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
            {props.logTitle === 'Report Request' ? null : <View><View style={ControlStyles.buttonContainer}>
              {canWriteData === true ?
                <ButtonControl icon="pencil" mode="contained" onPress={() => handleEditPress(selectedRows)} Title='Edit' disabled={selectedRows.length === 1 ? false : true} />
                :
                <ButtonControl icon="pencil" mode="contained" onPress={() => handleEditPress(selectedRows)} Title='Edit' disabled={true} />
              }
            </View></View>}
            {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
            {props.logTitle === 'Report Request' ? null : <View><View style={ControlStyles.buttonContainer}>
              {canWriteData === true ?
                <ButtonControl icon="delete-outline" mode="contained" onPress={() => handleDeletePress(selectedRows)} Title='Delete' disabled={selectedRows.length === 0 ? true : false} />
                :
                <ButtonControl icon="delete-outline" mode="contained" onPress={() => handleDeletePress(selectedRows)} Title='Delete' disabled={true} />
              }
            </View></View>}
            {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
            {props.logTitle === 'Report Request' ? null :
              <View>
                <View style={ControlStyles.buttonContainer}>
                  <ButtonControl icon="export-variant" mode="contained" onPress={() => handleExportClick(data, props.logTitle && `${siteTitle}_${props.logTitle}`)} Title='Export' disabled={data.length > 0 ? false : true} />
                </View>
              </View>
            }
          </ScrollView>
        }
      </View>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>
      {showConfirmation && (
        <View>
          <ConfirmBox title={"Confirmation"} message={"Are you sure you want to proceed to delete selected items?"} yesCallback={handleConfirmDelete} noCallback={handleCancelDelete} />
        </View>
      )}

      <View style={ControlStyles.tableContainer}>

        <DataTable>
          <ScrollView horizontal contentContainerStyle={{ flexDirection: 'column', flexGrow: 1 }}>
            {/* Table Header */}
            <DataTable.Header style={ControlStyles.dtHeader}>
              <DataTable.Title key={""} style={[ControlStyles.dtTitle, { width: 100 }]}>
                <Text style={ControlStyles.dtHeaderText}>Select</Text>
              </DataTable.Title>
              {columns.map((column, index) => (
                <DataTable.Title
                  key={index}
                  style={[ControlStyles.dtTitle, { width: column.title.length * 15 }]}
                  sortDirection={column.title === 'Item ID' ? 'descending' : null}
                >
                  <Text style={ControlStyles.dtHeaderText}>{column.title}</Text>
                </DataTable.Title>
              ))}
            </DataTable.Header>

            {/* Table Rows */}
            {data && data.length > 0 && data.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((row, index) => (
              <DataTable.Row
                key={index}
                onPress={() => handleRowClick(row)}
                style={{
                  backgroundColor: selectedRows.includes(row.ID) ? '#00B8EE' : index % 2 === 0 ? 'white' : '#4BB75F',
                }}
              >
                {/* Checkbox for selecting rows */}
                <DataTable.Cell key={"checkbox"} style={[ControlStyles.dtCell, { width: 100 }]}>
                  <Checkbox.Android
                    status={selectedRows.includes(row.ID) ? 'checked' : 'unchecked'}
                    onPress={() => handleCheckboxPress(row)}
                  />
                </DataTable.Cell>

                {/* Data Columns */}
                {columns.map((column, colIndex) => (
                  <DataTable.Cell key={colIndex} style={[ControlStyles.dtCell, { width: column.title.length * 15 }]}>
                    {column.columnName === 'Location' && row[column.columnName] ? (
                      row[column.columnName] && row[column.columnName].includes("https") ? (
                        <TouchableOpacity onPress={() => GoToLocation(row[column.columnName])}>
                          <IconButton
                            icon="map-marker-path"
                            iconColor={MD3Colors.error10}
                            size={20}
                          />
                        </TouchableOpacity>
                      ) : <Text style={ControlStyles.dtCellText}>{row[column.columnName]}</Text>
                    ) :
                      column.columnName === 'Attachments' ?
                        row[column.columnName] > 0 ?
                          <View style={{ flexDirection: 'row' }}>
                            <View>
                              <Tooltip title={row[column.columnName] + " files attached"}>
                                <IconButton
                                  icon="paperclip"
                                  iconColor={MD3Colors.error10}
                                  size={20}
                                />
                              </Tooltip>
                            </View>
                            <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <Text>{row[column.columnName]}</Text>
                            </View>
                          </View>
                          :
                          null
                        :
                        (
                          <Text style={ControlStyles.dtCellText}>{row[column.columnName]}</Text>
                        )
                    }
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </ScrollView>
          <DataTable.Pagination
            numberOfItemsPerPageList={optionsPerPage}
            numberOfItemsPerPage={itemsPerPage}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(page) => setPage(page)}
            onItemsPerPageChange={setItemsPerPage}
            showFastPaginationControls
            selectPageDropdownLabel={'Rows per page'}
            label={`${page * itemsPerPage + 1}-${Math.min(
              (page + 1) * itemsPerPage,
              data.length
            )} of ${data.length}`}
            style={{ alignSelf: 'flex-start' }}
          />
        </DataTable>

      </View>
    </View>
  );

}

export default DT;