import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, useWindowDimensions, ScrollView, Text, Platform, TouchableOpacity } from 'react-native';
//import { Input, Button } from 'react-native-elements';
import { useForm, Controller } from 'react-hook-form';
import TextField from '../controls/TextBox';
import NumberField from '../controls/NumberField';
import PaperDates from '../controls/datePicker-paperDates';
import { DropdownControl } from '../controls/Dropdown-sharingan-rn';
import ButtonControl from '../controls/Button';
import { createItem, updateItem } from '../service/dataOperations';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ToastMessage from '../controls/Toast';
import formCustomStyles from './formStyles';
import { getQuantityCalculation } from '../controls/BusinessLogic';
import * as ExpoDocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import DocPicker from '../controls/DocPicker';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import ConfirmBox from '../../../Common/ConfirmBox';
import ReactDropdownSingleSelect from '../controls/Dropdown-Single';

interface PaperFormProps {
  formMode: string;
  fields: Array<any>;
  selectedRow: Array<any>;
  closeForm: () => void;
  siteId: string;
  logTitle: string;
  updateFormState: () => void;
  ItemCreated: (Item: Array<any>) => void;
}

const PaperForm: React.FC<PaperFormProps> = React.memo((props) => {
  
  const [formStyle, setFormStyle] = useState(styles.formContainerMobile);
  const [formMode, setFormMode] = useState(props.formMode);
  const [Quantity, setQuantity] = useState(null);
  const [QuantityTonne, setQuantityTonne] = useState(null);
  const [fields, setFields] = useState(props.fields);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [itemId, setItemId] = useState(null);
  const [d, setD] = useState<any>('');

  const [docWarning, setDocWarning] = useState<boolean>(null);
  const [warningText, setWarningText] = useState<string>(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLoad, setFileLoad] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>();
  const [visible, setVisible] = React.useState(false);
  const [fileApi, setFileApi] = useState(null);
  const [downloadedFileUri, setDownloadedFileUri] = useState(null);
  const [docType, setDocType] = useState(null);

  let item: any;
  //let fields = props.fields;
  useEffect(() => {
    let formFields = props.fields;
    if (formMode === 'Edit' || formMode === 'View') {
      //debugger;
      item = props.selectedRow[0] && props.selectedRow[0];
      formFields = item && props.fields.map((field) => {
        // if(field.subType === 'date')
        // {
        //   console.log("Selected Date: "+ item[field.key]);
        // }
        //const defaultValue = field.subType === 'date'? item[field.key] && returnDate(item[field.key]):item[field.key];
        const defaultValue = item[field.key] && item[field.key];
        return { ...field, defaultValue };
      });
      //debugger;
      const AttachmentColumn = formFields && formFields.find((c) => c.subType === "attachment");
      if (AttachmentColumn && AttachmentColumn.defaultValue) {
        setUploadedFiles(JSON.parse(AttachmentColumn.defaultValue));
      }
      setFileApi(encodeURI(clientAPIURL + "api/sites/" + props.siteId + "/lists/" + props.logTitle + "/items/" + item.ID + "/attachments/"));
      setFields(formFields);
      setLoadForm(true);
      setItemId(item.ID);
    }
    else if (formMode === 'New') { setLoadForm(true) }
  }, [formMode, props.selectedRow, props.fields]);
  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm();

  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  function MonthYear(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const month = adjustedDate.toLocaleString('en-US', { month: 'short' });
      const year = adjustedDate.getFullYear();
      return month + "-" + year;
    } else {
      return null;
    }
  }
  const onSubmit = async (d: any) => {
    const MonthANDYear = d.Date && d.Date != null ? MonthYear(d.Date) : null;
    Object.keys(d).map((f: any) => {
      if (f === "QuantityTonne") {
        d.QuantityTonne = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityGallons") {
        d.QuantityGallons = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityLiters") {
        d.QuantityLiters = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityGallon") {
        d.QuantityGallon = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
    });
    const Month = MonthANDYear === null ? null : MonthANDYear.split('-')[0];
    const Year = MonthANDYear === null ? null : MonthANDYear.split('-')[1];
    const uploadedFilesJson = JSON.stringify(uploadedFiles);
    const data = MonthANDYear ? { ...d, 'Attachments': uploadedFilesJson, Month, Year } : {...d};
    //setD(JSON.stringify(data));
    let oItem = data;
    const result = Object.keys(data).map((key) => {
      const column = fields.find((c) => c.key === key);
      if (!column) {
        return null;
      }
      const value = data[key];
      const dataType = column.datatype.toLowerCase();
      if (dataType === 'datetimes') {
        delete oItem.key;
        oItem = { ...oItem, key: value };
      }
      const formattedValue =
        dataType === 'strings'
          ? value
          : dataType === 'numbers'
            ? Number(value)
            : dataType === 'datetimes'
              ? value
              : null;

      return {
        column_Name: column.key,
        [`${dataType}_Value`]: formattedValue,
        dataype: dataType,
      };
    }).filter((x) => x !== null);

    let response: any;
    if (formMode === 'Edit') {
      const accessToken: any = await getAccessToken();
      response = await updateItem(props.logTitle, accessToken, clientAPIURL, props.siteId, itemId, result);

    } else {
      const accessToken: any = await getAccessToken();
      response = await createItem(props.logTitle, accessToken, clientAPIURL, props.siteId, result);
    }

    //if(response.status === 401 || response.statusText === "Unauthorized" || response.statusText === "Bad Request")
    if (!response) {
      setToastText("Operation Failed: You are not authorized to perform this action.")
      setToastVisibility(true);
    }
    if (response) {
      setToastText("Your changes are saved.")
      setToastVisibility(true);
      //const dataArray = [data];
      setTimeout(function () {
        if (formMode === 'Edit') {
          //const createdItem = oItem && {...oItem, "ID": itemId, "key": itemId };
          if (oItem) {
            oItem["ID"] = itemId
            oItem["key"] = itemId
          }
          const createdItem = oItem
          props.ItemCreated(createdItem);
        }
        else {
          const createdItem = oItem //&& { ...oItem, "ID": response, "key": response };
          if (oItem) {
            oItem["ID"] = response
            oItem["key"] = response
          }
          props.ItemCreated(createdItem);
        }
      }, 2000)
    }
  };
  const invalidNumber = () => {
    setToastText("Invaid Entry: Please enter valid number to numerical column.")
    setToastVisibility(true);
  }
  function returnDate(value: Date) {
    console.log("value: " + value);
    if (value) {
      try {
        const date = new Date(`${value.split('/')[0]}/${value.split('/')[1]}/${value.split('/')[2]}`);
        console.log(date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
        console.log("formattedDate: " + formattedDate);
        return formattedDate
      }
      catch (ex) {
        return value
      }
    }
    else return value

  }
  const handleClose = () => {
    props.closeForm()
  }

  const handleToastDismiss = () => {
    setToastVisibility(false);
  };

  const onFileUploaded = (fileObj: any) => {
    let parsedFileData = JSON.parse(fileObj);
    setUploadedFiles(prevFiles => [...prevFiles, parsedFileData]);
  }

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [fileId, setFileId] = useState<any>(null);

  function confirmDelete(id: any) {
    setFileId(id)
    showConfirmationBox();
  }
  function showConfirmationBox() {
    setShowConfirmation(!showConfirmation);
  }
  function removeFile() {
    const newFiles = fileId && uploadedFiles.filter(file => file.fileId !== fileId);
    setUploadedFiles(newFiles);
    showConfirmationBox();
  }

  const downloadFile = async (url: string, token: void, fileName: string) => {

    if (Platform.OS === 'ios') {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    } else if (Platform.OS === 'android') {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        alert('Sorry, we need write storage permissions to make this work!');
      }
    }

    const uri = url;
    setFileType(uri.split('.').pop().toLowerCase());
    setDocType(uri.split('.').pop().toLowerCase());
    const fileUri = FileSystem.documentDirectory + fileName;
    let options = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    };
    console.info('Trying to download ' + uri);
    console.info('Download Loc ' + fileUri);
    FileSystem.makeDirectoryAsync(FileSystem.documentDirectory, { intermediates: true })
      .then(() => {
        FileSystem.downloadAsync(uri, fileUri, options)
          .then(({ uri }) => {
            console.log('Finished downloading to ', uri);
            setDownloadedFileUri(uri);
            showModal();
            setFileLoad(false);
          })
          .catch(error => {
            console.error(error);
          });
      })
      .catch(error => {
        console.error('Error creating directory:', error);
      });
  }
  const openFile = async (fileId: string, fileName: string) => {
    setFileLoad(true);
    const api = fileApi + fileId;
    const accessToken = await getAccessToken();
    if (Platform.OS == "web") {
      try {

        const response = await fetch(api, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        if (!response.ok) {
          console.error("Network response was not ok");
        }
        const blob = await response.blob();
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        window.URL.revokeObjectURL(blobURL);
        showModal();
        setFileLoad(false);
      }
      catch (err) {
        // Handle error if file couldn't be opened
        console.log('Error opening file: ', err);
      }
    }

    else {
      downloadFile(api, accessToken, fileName);
    }

  }
  const fieldStyle = formMode === "View" ? formCustomStyles.fieldContianerViewMode : formCustomStyles.fieldContianer
  return (
    loadForm === false ? null :
      <View style={formStyle}>
        <ScrollView>
          <>
            <View style={[{ flex: 1, margin: 10 }, styles.buttonsContainer]}>
              {
                formMode === "View"
                  ?
                  // <View style={[{ flex: 1 }, styles.buttonsContainer]}>
                  //   <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' />
                  // </View>
                  <View>
                    <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  </View>
                  :
                  <ScrollView horizontal>
                    <View style={styles.buttonsContainer}>
                      <View style={{}}>
                        <ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' />
                      </View>
                      <View style={{}}>
                        <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' />
                      </View>
                    </View>
                  </ScrollView>
              }
            </View>



            {/* Attachment Section Start */}
            {docWarning === true ?
              <View>
                <Text style={{ color: 'red' }}>{warningText}</Text>
              </View>
              : null}
            <View style={{ borderWidth: 2, borderColor: '#DDDDDD', paddingLeft: 10 }}>
              <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{"Attachments:"}</Text>
              <View style={{ flexDirection: 'column' }}>
                {formMode === "View" ? null :
                  <View style={{ padding: 20 }}>
                    <DocPicker formMode={formMode} onFileUploaded={onFileUploaded} clientAPIURL={clientAPIURL} showWarning={setDocWarning} warningText={setWarningText} />
                  </View>
                }
                {/* <View style={{ padding: 20, width: 240 }}>
                    {
                      formMode === "View" ? null :
                        Platform.OS === 'android' || Platform.OS === 'ios' ?
                          <ButtonControl icon='camera' mode="contained" onPress={() => setCamVisible(true)} Title='Camera' />
                          : null
                    }
                  </View> */}
                {docWarning === true ?
                  <View>
                    <Text style={{ color: 'red' }}>{warningText}</Text>
                  </View>
                  : null}
              </View>
              <View style={{ paddingLeft: 20 }}>

                {uploadedFiles.map((file: { fileId: string, fileName: string }, index) => (
                  formMode === "View" || formMode === "Edit" ?
                    <View key={index} style={{ flexDirection: "row", alignItems: "center", height: 50 }}>
                      <TouchableOpacity onPress={() => openFile(file.fileId, file.fileName)}>
                        <Text style={{ marginRight: 10 }}>Attachment {index + 1}: <Text style={{ color: 'blue', textDecorationLine: 'underline' }}>{file.fileName}</Text></Text>
                      </TouchableOpacity>
                      {formMode === "Edit" ? <IconButton
                        icon="close-circle-outline"
                        iconColor={MD3Colors.error50}
                        size={20}
                        onPress={() => confirmDelete(file.fileId)}
                      /> : null}
                    </View>
                    :
                    <View key={index} style={{ flexDirection: "row", alignItems: "center", height: 50 }}>
                      <Text style={{ marginRight: 10 }}>Attachment {index + 1}: {file.fileName}</Text>
                      <Tooltip title="Remove attachment">
                        <IconButton
                          icon="close-circle-outline"
                          iconColor={MD3Colors.error50}
                          size={20}
                          onPress={() => { confirmDelete(file.fileId) }}
                        />
                      </Tooltip>
                    </View>
                ))}
                {showConfirmation && (
                  <View>

                    <ConfirmBox title={"Confirmation"}
                      message={"Are you sure you want to proceed to delete selected items?"}
                      yesCallback={removeFile}
                      noCallback={showConfirmationBox} />

                  </View>
                )}
              </View>
              <View style={{ padding: 10 }}></View>
            </View>
            {/* Attachment Section End */}



            <View style={formCustomStyles.allFieldsContainer}>
              {fields.map((field, index) => {
                if (field.hidden === true) {
                  return null
                }
                else {
                  switch (field.subType) {
                    case 'text':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <TextField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          />
                        </View>
                      );
                    case 'choice':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <ReactDropdownSingleSelect
                            field={field}
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            control={control}
                            Ctrl={Controller}
                            errors={errors}
                          />
                          {/* <DropdownControl
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          field={field}
                          control={control}
                          Ctrl={Controller}  
                          errors={errors}                        
                        /> */}
                        </View>
                      );
                    case 'float':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                            logTitle={props.logTitle}
                            fields={fields}
                            setFields={setFields}
                            invalidNumber={invalidNumber}
                          //ref={register}
                          /></View>
                      );
                    case 'integer':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                            logTitle={props.logTitle}
                            fields={fields}
                            setFields={setFields}
                            invalidNumber={invalidNumber}
                          //ref={register}
                          /></View>
                      );
                    case 'date':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <PaperDates
                            key={field.key.toString()}
                            field={field}
                            editable={formMode === 'View' ? false : true}
                            control={control}
                            styles={styles.date}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          /></View>
                      );
                    default:
                      return null//<View key={index} style={[fieldStyle]}><Text>{field.key}</Text></View>;
                  }
                }
              }
              )}
            </View>
            <View style={{ margin: 10 }}>
              {
                formMode === "View"
                  ?
                  // <View style={[{flex:1}, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  :
                  <ScrollView horizontal style={{ width: '100%' }}>
                    <View style={styles.buttonsContainer}>
                      <View style={{}}><ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' /></View>
                      <View style={{}}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                    </View>
                  </ScrollView>
              }
              {/* <Input placeholder={''} value={d} /> */}
            </View>
            <View>
              <ToastMessage
                show={toastVisibility}
                actionLabel="Dismiss"
                toastMessage={toastText}
                duration={3000}
                onDismiss={handleToastDismiss}
              />
            </View>
          </>
        </ScrollView>
      </View>
  );
});

const styles = StyleSheet.create({
  container: {
    //justifyContent: 'center',
    //alignItems: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',

    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  formContainerPC: {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  formContainerMobile: {
    borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    backgroundColor: '#FFFFFF',
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    //marginLeft: '5%',
    //marginRight: '5%',
    //paddingLeft: '2%',
    //paddingRight: '2%',
  },
  input: {
    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    minHeight: 60,
    maxHeight: 60,
  },
  date: {
    minWidth: '100%',
    minHeight: 60,
    maxHeight: 60,

    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4
  },
  buttonsContainer: {
    flex: 1,
    //padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    //alignItems: 'center',
    //justifyContent: 'space-evenly',
    height: 60
  }
});

export default PaperForm;
